import http from "../http-common";

const url = "api/talent/profile/"
const avatarUrl ="api/talent/avatar/"

class TalentDataService {
  get(token) {
    http.defaults.headers['Authorization'] = `Token ${token}`
    return http.get(url);
  }

  edit(data, token) {
    http.defaults.headers['Authorization'] = `Token ${token}`
    return http.put(url, data);
  }

  changeAvatar(avatar, token) {
    http.defaults.headers['Authorization'] = `Token ${token}`
    return http.post(avatarUrl, avatar);
  }

  async getAvatar(token) {
    http.defaults.headers['Authorization'] = `Token ${token}`
    return http.get(avatarUrl);
  }

  // update(id, data) {
  //   return http.put(`${url+id}/`, data);
  // }

  // delete(id) {
  //   return http.delete(`${url+id}/`);
  // }

}

export default new TalentDataService();
