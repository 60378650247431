
import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux'
import { selectAuth } from '../../../state/authSlice'
import { selectTalent } from '../../../state/talentSlice'
import { selectTalentSavedJobs, fetchTalentSavedJobs } from '../../../state/talentSavedJobsSlice'

import { capitalizeFirstLetter } from '../../../constants'

// Data Services
import ProjectTypeDataService from '../../../services/projectType.service'
import ProjectSubtypeDataService from '../../../services/projectSubtype.service'
import EmpTypeDataService from '../../../services/empType.service'
import CompanyDataService from '../../../services/company.service'
import TalentSavedJobsService from '../../../services/talentSavedJobs.service'

// reactstrap components
import {
    Row,
    Col,
    Card,
    CardBody,
    CardImg,
    CardTitle,
    CardText,
} from "reactstrap"

import { Skeleton, notification, message } from 'antd'
import { WarningFilled } from '@ant-design/icons'

import BusinessIcon from '@material-ui/icons/Business';
import PinDrop from '@material-ui/icons/PinDrop';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';

const JobPostCard = (props) => {
    const [icon, setIcon] = useState(<BookmarkBorderIcon style={{color: "#ed5e94"}}/>)
    const [isSaved, setIsSaved] = useState(false)
    const [empType, setEmpType] = useState("")
    const [projType, setProjType] = useState("")
    const [company, setCompany] = useState("")

    const dispatch = useDispatch()
    const auth = useSelector(selectAuth)
    const talent = useSelector(selectTalent)
    const talentSavedJobs = useSelector(selectTalentSavedJobs)

    useEffect(() => {

        // var projSubtype = await ProjectSubtypeDataService.get(job.project_sub_type, auth.token)

        // Get Employment type
        EmpTypeDataService.get(props.empType, auth.token)
        .then((res) => setEmpType(capitalizeFirstLetter(res.data.name.replaceAll("_"," ").toLowerCase())))

        // Get Company if defined
        if(props.company != undefined && props.showCompany) {
            CompanyDataService.get(props.company, auth.token)
            .then((res) => setCompany(capitalizeFirstLetter(res.data.name)))
        }

        // Check if job is saved
        if(talentSavedJobs.includes(props.jobId)) {
            setIsSaved(true)
            setIcon(<BookmarkIcon style={{color: "#ed5e94"}}/>)
        } else {
            setIcon(<BookmarkBorderIcon style={{color: "#ed5e94"}}/>)
        }

    }, [auth, talent, setEmpType, setCompany, setIcon, setIsSaved]) 

    const HandleSaveJob = () => {

        var data = new FormData()
        data.append('jobpost_id', props.jobId)
        TalentSavedJobsService.create(data, auth.token, {
            headers: {
            // Overwrite Axios's automatically set Content-Type
            'content-type': 'multipart/form-data'
            }
        })
        .then(() => {
            setIcon(<BookmarkIcon style={{color: "#ed5e94"}}/>)
            setIsSaved(true)
            message.success("Saved!")
            // re-fetch saved jobs data on store
            dispatch(fetchTalentSavedJobs(auth.token))

        })
        .catch((err) => {
            console.log(err.response.data)
            notification.open({
            message: 'Error',
            description: err.message,
            icon: <WarningFilled style={{ color: '#ed5e94' }} />,
            })
        })  
        
    }

    const HandleUnsaveJob = () => {


        TalentSavedJobsService.getAll(auth.token)
        .then(res => {
            
            for(let i = 0; i < res.data.length; i++) {
                let data = res.data[i]
                if(data.jobpost_id === props.jobId) {
                    TalentSavedJobsService.delete(data.id, auth.token)
                    .then(() => {
                        setIcon(<BookmarkBorderIcon style={{color: "#ed5e94"}}/>)
                        setIsSaved(false)
                        message.success("Removed from saved jobs!")
                        // re-fetch saved jobs data on store
                        dispatch(fetchTalentSavedJobs(auth.token))
                    })
                    break;
                }
            }
            
        })
        .catch((err) => {
            console.log(err.response.data)
            notification.open({
            message: 'Error',
            description: err.message,
            icon: <WarningFilled style={{ color: '#ed5e94' }} />,
            })
        })  
        
    }
    
    return(
        <Card style={{background: props.bgColor, width: props.width, border: "0", position: "relative"}}>
            <CardBody>
            <div style={{color: props.textColor}}>
                <div><span className="h4 font-weight-medium mb-0" style={{color: props.textColor}}>{props.title}</span></div>
                <div><span className="text-muted mb-0">{empType}</span></div>

                
                {auth.userType === 'PRODUCTION_MANAGER' && (<div 
                className="card-label" 
                style={{position:"absolute", right: "25px", top: "15px"}}>
                    {props.labelTop}
                </div>)}

                {/* Label top is an icon toggle to save job post*/}
                {auth.userType === 'TALENT' && (isSaved ? (
                    <div 
                    className="card-label font-weight-bold" 
                    style={{position:"absolute", right: "25px", top: "15px"}}
                    onClick={() => HandleUnsaveJob()}>
                        <a>{icon}</a>
                    </div>
                ) : (
                    <div 
                    className="card-label font-weight-bold" 
                    style={{position:"absolute", right: "25px", top: "15px"}}
                    onClick={() => HandleSaveJob()}>
                        <a>{icon}</a>
                    </div>
                ))}
                

                <div className="card-label font-weight-bold" style={{position:"absolute", right: "25px", bottom: "15px"}}>{props.labelBottom}</div>

                <div className="d-flex flex-wrap">
                    {company && ( 
                        <p className="mt-3 mb-0 text-xs">
                            <span className="mr-2">
                            <BusinessIcon style={{color: props.iconColor}}/>   {company}
                            </span>
                        </p>
                    )}
                    
                    {props.location && (
                        <p className="mt-3 mb-0 text-xs">
                            <span className="mr-2">
                            <PinDrop style={{color: props.iconColor}}/>   {props.location}
                            </span>
                        </p>
                    )}
                    
                </div>
                
                {/* <Col className="col-auto">
                    {props.icon}
                </Col> */}
            </div>

            {props.extra}
            
            </CardBody>
        </Card>  
    )                           
}

export default JobPostCard;