import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// Data services
import GenderDataService from '../services/gender.service'

const initialState = {
    genders: [],
    status: 'idle',
    error: null
}




export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {},
  extraReducers: {
    
  }
});

export const selectGenders = (state) => state.common.talents


export default commonSlice.reducer
