import React, { Component } from "react"
import { Link } from 'react-router-dom'

import { Container } from "reactstrap"

/**
 *  Header component - only consist of title and a any other extra elements passed through props
 *  @version 1.0
 *  @author Nadia Mayangputri
 */ 
class Header extends Component {
    render() {

        return(
            <Container className="header pt-md-3 pt-lg-5">
                {this.props.breadcrumb}
                <div className="spaced-between w-100">
                    <div className="text-group mt-md-4">
                        <h1>{this.props.title}</h1>
                        {this.props.subtext}
                    </div>
                    <div>
                        {this.props.extra}
                    </div>
                    
                </div>
            </Container>
        )
    }
}

export default Header;