import React, { Component } from 'react'
import { Link } from 'react-router-dom'

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardImg,
    CardTitle,
    CardText,
    Container,
    Badge
  } from "reactstrap"

import AddIcon from '@material-ui/icons/Add';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import JobClassificationDataService from "../../../../services/jobClassification.service";
import TalentJobClassificationService from "../../../../services/talentJobClassification.service";

import "./TalentCard.css"

/**
 *  TalentCard component - card display for talents
 *  @version 1.0
 *  @author Nadia Mayangputri
 */ 
export default class TalentCard extends Component {
    state = {
        jobClassifications: [],
        dataLoaded: false
    }

    render() {
    // if avatar is null, set avatar to default profile image
    let avatar = this.props.img ||  "/blank-profile-picture.png"

    // some talent cars don't need a link to their profile (e.g card in talent home)
    let profileLink = 
        <Link to={`/talents/${this.props.identifier}`} className="profile-btn" >
            <Button
                className="btn-icon btn-3" 
                type="button" 
                color="secondary"
                outline>
                <span className="btn-inner--text">View Profile</span>
                <span className="btn-inner--icon">
                    <NavigateNextIcon style={{fontSize: "0.9em"}}/>
                </span>
            </Button>
        </Link>

    if(!this.props.showProfileLink) {
        profileLink = <br></br>
    }
    let talentCard = <Card className="talent-card" style={{margin: this.props.margin}}>
        <div 
            className="img-fluid rounded-circle shadow-lg avatar"
            style={{backgroundImage: `url(${avatar})`, width: `${this.props.imgSize}`, height: `${this.props.imgSize}`, marginTop: `${this.props.imgMargin}`}}></div>
        <div className="btn-wrapper" style={{display: this.props.btnDisplay}}>
            <Button color="info" type="button">
                Connect
            </Button>

            <Button color="default" type="button">
                Message
            </Button>                
        </div>

        
        <CardBody>
            <CardTitle><b>{this.props.name}</b></CardTitle>
            <CardText>
                <span>
                    {this.props.text}
                </span>
            </CardText>
            
            {profileLink}
        </CardBody>
    </Card>
    
    return(
        <>
            {talentCard}
        </>
    )}
}