import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// Data services
import TalentSavedJobsService from "../services/talentSavedJobs.service"
import JobPostService from '../services/jobPost.service'


const initialState = {
  data: [],
  status: 'idle',
  error: null
}

export const fetchTalentSavedJobs = createAsyncThunk('talent/fetchTalentSavedJobs', async (token) => {
  const response = await TalentSavedJobsService.getAll(token)
  
  var ids = [];
  var data = [];

  response.data.forEach((savedJob) => {
    ids.push(savedJob.jobpost_id)
    JobPostService.get(savedJob.jobpost_id, token)
    .then(res => {data = [...data, res.data]})
    
  })

  var savedJobs = {
    ids: ids,
    data: data
  }

  return ids

})

// Talent data slice
export const talentSavedJobsSlice = createSlice({
  name: 'talentSavedJobs',
  initialState,
  reducers: {
    resetTalentSavedJobs: (state) => {
      state.data = initialState.data
    }
  },
  extraReducers: {
    [fetchTalentSavedJobs.pending]: 
      (state, action) => {
      state.status = 'loading'
    },
    [fetchTalentSavedJobs.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    },
    [fetchTalentSavedJobs.fulfilled]: (state, action) => {
      state.data = action.payload
      state.status = 'succeeded'
    }
    
  }
});

export default talentSavedJobsSlice.reducer

export const { resetTalentSavedJobs } = talentSavedJobsSlice.actions;

export const selectTalentSavedJobs = (state) => state.talentSavedJobs.data

  