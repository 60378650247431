import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

//Redux store
import { useSelector } from 'react-redux'
import { selectAuth } from '../../../state/authSlice'
import { selectPublishedJobPosts, selectDraftedJobPosts, selectClosedJobPosts } from '../../../state/jobPostSlice'


// Data services
import JobPostService from "../../../services/jobPost.service";

// UI Components Libary
import { Container } from 'reactstrap';
import { Skeleton, Tabs, Divider, Empty, Radio } from 'antd';

// Custom Components
import JobPostCard from "../../common/JobPost/JobPostCard"
import Header from '../../common/Header/Header';
import EmptyCard from '../../common/Card/EmptyCard'
import JobPostForm from './JobPostForm'

// Icons
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import VisibilityIcon from '@material-ui/icons/Visibility';

import './AdminJobPosts.css'

const { TabPane } = Tabs;


const AdminJobPost = () => {
    const publishedJobs = useSelector(selectPublishedJobPosts)
    const draftedJobs = useSelector(selectDraftedJobPosts)
    const closedJobs = useSelector(selectClosedJobPosts)


    // Status of data fetch on redux store
    const status = useSelector((state) => state.common.status)
    const auth = useSelector(selectAuth)


    const [activeTab, setActiveTab] = useState('1')
    const [statusFilter, setStatusFilter] = useState('all')

    const changeTab = (key) => {
        setActiveTab(key)
    }

    const postJob = async (data) => {
        const res = await JobPostService.create(data, auth.token, {
            headers: {
              // Overwrite Axios's automatically set Content-Type
              'content-type': 'multipart/form-data'
            }
        })

        return res
    }

    const handleToggleChange = (e) => {
        setStatusFilter(e.target.value)
    }

    return(
        <div className="admin-job-posts-container pt-4">
            {/* <div className="bg-illustration">
                <img src={require("../../../assets/background/bg-light.png")}></img>
            </div> */}


            <div className="tab-container">
                <Tabs defaultActiveKey="1" className="tab-content" onTabClick={(key) => changeTab(key)} activeKey={activeTab}>
                    <TabPane tab="MANAGE JOB LISTINGS" key="1">

                        <Container className="spaced-between mt-2">
                            <h1>Job Listings</h1>

                            {/* Filter toggle */}
                            <Radio.Group defaultValue="all" optionType="button" buttonStyle="solid" onChange={(e) => handleToggleChange(e)}>
                                <Radio.Button value="all" className="btn-pink-reversed rounded-left">All</Radio.Button>
                                <Radio.Button value="published" className="btn-pink-reversed ">Published</Radio.Button>
                                <Radio.Button value="draft" className="btn-pink-reversed ">Draft</Radio.Button>
                                <Radio.Button value="closed" className="btn-pink-reversed rounded-right">Closed</Radio.Button>
                            </Radio.Group>
                        </Container>

                        {/* Published job posts */}
                        {(statusFilter === 'all' || statusFilter === 'published') && (
                        <>
                            <Container className="mt-5">
                                <span className="text-uppercase text-muted">Published</span>
                            </Container>
                            <Container className="grid-container mt-3">
                                {publishedJobs.length > 0 ? (publishedJobs.map((job) => {
                                    var bgColor = "#EFF1F6", color = "#35424a"; 
                                    var applicantsNum = 0;
                                    var viewsCount = 30;

                                    return(
                                        
                                        <JobPostCard 
                                            title={job.job_title}
                                            // subtext={`${projSubtype.data.name} | ${empType.data.name}`}
                                            company={job.company_id}
                                            showCompany={true}
                                            location={job.city + ", "+ job.country}
                                            bgColor={bgColor}
                                            bgColor={bgColor}
                                            textColor={color}
                                            labelTop={<div className="mt-2 w-100 text-xs">{moment(job.dt_published, "YYYY-MM-DD").fromNow()}</div>}
                                            width="100%"
                                            labelBottom={
                                                <Link className="link" to={`/job-posts/${job.id}`}>
                                                    <button className="btn rounded m-0 pt-1 pb-1 pr-4 pl-4 dark-blue-btn">View</button>
                                                </Link>
                                            }
                                            extra={
                                            <div className="mt-3">
                                                {/* <div className="mr-3"><VisibilityIcon style={{color: '#ed5e94', fontSize: '1.2em'}}/> <b>{viewsCount}</b> Views</div> */}
                                                <div><AssignmentTurnedInIcon style={{color: '#ed5e94', fontSize: '1.2em'}} /> <b>{applicantsNum}</b> Applicants</div>
                                            </div>}
                                        />
                                        
                                    )
                                })) : (
                                    // If it's loading, show loading card component, if it's not it means there is no data to load
                                    status === 'loading' ? (  
                                    <>
                                            <EmptyCard />
                                            <EmptyCard />
                                            <EmptyCard />
                                        </>
                                    ) : (
                                        <>
                                        <div className="d-none d-xl-block"></div>
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="You have no active job posts"/>
                                        <div className="d-none d-xl-block"></div>
                                        </>
                                    )
                                )}
                            </Container>
                        </>)}

                        {/* Drafted job posts */}
                        {(statusFilter === 'all' || statusFilter === 'draft') && (
                        <>
                            <Container className="mt-5">
                                <span className="text-uppercase text-muted">Draft</span>
                            </Container>
                            <Container className="grid-container mt-3">
                                {draftedJobs.length > 0 ? (draftedJobs.map((job) => {
                                    var bgColor = "#EFF1F6", color = "#35424a"; 
                                    var applicantsNum = 12;
                                    var viewsCount = 30;

                                    return(
                                        <JobPostCard 
                                            title={job.job_title}
                                            // subtext={`${projSubtype.data.name} | ${empType.data.name}`}
                                            company={job.company_id}
                                            bgColor={bgColor}
                                            textColor={color}
                                            width="100%"
                                            labelBottom={
                                                <Link className="link" to={`/job-posts/${job.id}`}>
                                                    <button className="btn rounded m-0 pt-1 pb-1 pr-4 pl-4 dark-blue-btn">View</button>
                                                </Link>
                                            }
                                        />
                                        
                                    )
                                })) : (
                                    // If it's loading, show loading card component, if it's not it means there is no data to load
                                    status === 'loading'  ? (  
                                        <>
                                            <EmptyCard />
                                            <EmptyCard />
                                            <EmptyCard />
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-none d-xl-block"></div>
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="You have no drafted job posts"/>
                                            <div className="d-none d-xl-block"></div>
                                        </>
                                    )
                                )}
                            </Container>
                        </>)}

                        {/* Closed job posts */}
                        {(statusFilter === 'all' || statusFilter === 'closed') && (
                            <>
                            <Container className="mt-5">
                                <span className="text-uppercase text-muted">Closed</span>
                            </Container>
                            <Container className="grid-container mt-3">
                                {closedJobs.length > 0 ? (closedJobs.map((job) => {
                                    var bgColor = "#EFF1F6", color = "#35424a"; 
                                    var applicantsNum = 0;
                                    var viewsCount = 30;

                                    return(
                                        <JobPostCard 
                                            title={job.job_title}
                                            // subtext={`${projSubtype.data.name} | ${empType.data.name}`}
                                            company={job.company_id}
                                            bgColor={bgColor}
                                            textColor={color}
                                            width="100%"
                                            labelBottom={
                                                <Link className="link" to={`/job-posts/${job.id}`}>
                                                    <button className="btn rounded m-0 pt-1 pb-1 pr-4 pl-4 dark-blue-btn">View</button>
                                                </Link>
                                            }
                                            extra={
                                            <div className="d-flex mt-2">
                                                {/* <div className="mr-3"><VisibilityIcon style={{color: '#ed5e94', fontSize: '1.2em'}}/> <b>{viewsCount}</b> Views</div> */}
                                                <div><AssignmentTurnedInIcon style={{color: '#ed5e94', fontSize: '1.2em'}} /> <b>{applicantsNum}</b> Applicants</div>
                                            </div>}
                                        />
                                        
                                    )
                                })) : (
                                    // If it's loading, show loading card component, if it's not it means there is no data to load
                                    status === 'loading'  ? (  
                                        <>
                                            <EmptyCard />
                                            <EmptyCard />
                                            <EmptyCard />
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-none d-xl-block"></div>
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="You have no closed job posts"/>                                   
                                            <div className="d-none d-xl-block"></div>
                                        </>
                                    )
                                )}
                            </Container>
                        </>)}
                    </TabPane>
                    <TabPane tab="POST JOB" key="2">
                        <Container>
                        <div className="spaced-between mt-2 mb-5">
                            <h1>Post a new Job Listing</h1>

                            <div>
                            <button onClick={() => changeTab('1')} className="rounded py-2 px-3 dark-blue-btn">Discard</button>
                            </div>
                        </div>
                            <JobPostForm submitHandler={postJob}/>
                        </Container>
                        
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
    
}

export default AdminJobPost;