import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// Data services
import LanguageDataService from "../services/language.service";
import LanguageProficiencyDataService from "../services/languageProficiency.service";


const initialState = {
    languages: [],
    languageProficiencies: [],
    status: 'idle',
    error: null
}


/**
   * Get all languages specialisations from languages data service
   */
export const fetchAllLanguages = createAsyncThunk('language/fetchAllLanguages', async (token) => {
    const response = await LanguageDataService.getAll(token)
    return response.data
})

/**
   * Get all language proficiencies specialisations from languages proficiency data service
   */
export const fetchAllLanguageProficiencies = createAsyncThunk('language/fetchAllLanguageProficiencies', async (token) => {
    const response = await LanguageProficiencyDataService.getAll(token)
    return response.data
})



export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAllLanguages.pending]: (state) => {
      state.status = 'language loading'
    },
    [fetchAllLanguageProficiencies.pending]: (state) => {
      state.status = 'language proficiency loading'
    },
    [fetchAllLanguages.fulfilled]: (state, action) => {
      state.languages = action.payload.reverse()
      state.status = 'language succeeded'
    },
    [fetchAllLanguageProficiencies.fulfilled]: (state, action) => {
      state.languageProficiencies = action.payload.reverse()
      state.status = 'language proficiency succeeded'
    },
    [fetchAllLanguages.rejected]: (state, action) => {
      state.status = 'language failed'
      state.error = action.payload
    },
    [fetchAllLanguageProficiencies.rejected]: (state, action) => {
      state.status = 'language proficiency failed'
      state.error = action.payload
    }
  }
});

export const selectAllLanguages = (state) => state.language.languages
export const selectAllLanguageProficiencies = (state) => state.language.languageProficiencies


export default languageSlice.reducer
