import React, { Component } from 'react';
import { connect } from "react-redux";

import { Field, Form, Formik, FieldArray } from "formik";
import * as Yup from 'yup';
import moment from 'moment';
import { Redirect } from 'react-router-dom'

import './TalentForm.css'

import {
    FormGroup,
    Card,
    CardBody,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
    Button,
    Modal
  } from "reactstrap";

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import { MenuItem } from '@material-ui/core'

import {
    TextField,
    Select
} from 'formik-material-ui';

import FormikDateTime from '../../common/FormikDateTime'

import Header from "../../common/Header/Header"


import TalentDataService from "../../../services/talent.service";
import TalentJobClassificationService from "../../../services/talentJobClassification.service";
import TalentLanguageService from "../../../services/talentLanguage.service";
import TalentLanguagePairService from "../../../services/talentLanguagePair.service";
import TalentProjectService from "../../../services/talentPastExperience.service";

import JobClassificationDataService from "../../../services/jobClassification.service";
import SpecialisationDataService from "../../../services/specialisation.service";
import LanguageDataService from "../../../services/language.service";
import LanguageProficiencyDataService from "../../../services/languageProficiency.service";

const DATE_FORMAT = 'YYYY-MM-DD'

const talentFormSchema = Yup.object().shape({
  givenName: Yup.string()
    .max(50, 'Too Long!')
    .required('Required'),
  surname: Yup.string()
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  gender: Yup.string()
    .required('Required')
});



class TalentForm extends Component {
    state = {
      uploaded_avatar: '',
      preview_avatar: require('../../../assets/blank-profile-picture.png'),
      specialisation: {},
      jobClassifications: [],
      specialisations: [],
      languages: [],
      languageProficiencies: [],
    }
    
    componentDidMount() {
      this.resetState();
    }

    /**
   * Get all job classification/skills from job classification data service
   */
  getJobClassification = () => {

    JobClassificationDataService.getAll()
      .then(res => {
        this.setState({
          jobClassifications: res.data
        });
      })
      .catch(e => {
        console.log(e);
      });
  } 

  /**
   * Get all job classification specialisations from specialisations data service
   */
  getSpecialisation = () => {

    SpecialisationDataService.getAll()
      .then(res => {
        this.setState({
          specialisations: res.data
        });
      })
      .catch(e => {
        console.log(e);
      });
  } 

  /**
   * Get all languages specialisations from languages data service
   */
  getLanguage = () => {
    LanguageDataService.getAll()
      .then(res => {
        this.setState({
          languages: res.data
        });
      })
      .catch(e => {
        console.log(e);
      });
  } 

  /**
   * Get all language proficiencies specialisations from languages proficiency data service
   */
  getLanguageProficiency = () => {
    LanguageProficiencyDataService.getAll()
      .then(res => {
        this.setState({
          languageProficiencies: res.data
        });
      })
      .catch(e => {
        console.log(e);
      });
  }

  /**
   * Reset state by re-fetching data
   */
  resetState = () => {
    this.getJobClassification();
    this.getSpecialisation();
    this.getLanguage();
    this.getLanguageProficiency()
  };

    
    
    handleFileUpload = (event) => {
      let reader = new FileReader();
      let file = event.target.files[0];
      reader.onloadend = () => {
        this.setState({
          preview_avatar: reader.result,
        });
      };
      reader.readAsDataURL(file);

      this.setState({uploaded_avatar: file})
    }
    
    async getSpecialisationData(id) {
      console.log(id)
      if(id != null || id !== "" ||id != undefined) {
        return SpecialisationDataService.get(id)
        .then((res) => {
          console.log(res.data)
          this.setState({specialisation: res.data})
        })
      }
  }

    createCheckboxGroup = (arr, dataTarget, dataName) => {
      return(
        <FieldArray
            name={dataName}
            render={arrayHelpers => (
            
              <div>
                {arr.map(arrItem => (
                  <div key={arrItem.id} className="custom-control custom-checkbox mb-3">
                     
                      <input
                        className="custom-control-input"
                        name={dataName}
                        type="checkbox"
                        value={arrItem.id}
                        id={arrItem.id+dataName}
                        checked={dataTarget.includes(arrItem.id)}
                        onChange={e => {
                          if (e.target.checked) arrayHelpers.push(arrItem.id);
                          else {
                            const idx = dataTarget.indexOf(arrItem.id);
                            arrayHelpers.remove(idx);
                          }
                        }}
                      />
                    <label className="custom-control-label" htmlFor={arrItem.id+dataName}>
                      {arrItem.name}
                    </label>
                  </div>
                ))}
              </div>
            )}
          />
      )
    }

    render() {
      return(
      <div className="new-talent-form-container">
      
      <Header title="New Talent" btnTitle="Discard" btnLink="/talents" btnIcon={<DeleteIcon style={{fontSize: "1em"}}/>} btnColor="danger"/>

      
      <Formik
      initialValues={{ 
          avatar: null,
          givenName: "",
          surname: "",
          nickname: "",
          headline: "",
          birthdate: "",
          gender: "",
          email: "", 
          mobile: "",
          jobClassifications: [],
          specialisations: [],
          vocalRange: "",
          languages: [],
          languagePairs: [],
          projects: []
      }}
      validationSchema={talentFormSchema}
      onSubmit={(values, { setSubmitting }) => {
        //Make API calls here
    
        let basicInfoData = new FormData();
        if(values.avatar != null) {basicInfoData.append('avatar', values.avatar, values.avatar.name);}
        basicInfoData.append('given_name', values.givenName);
        basicInfoData.append('surname', values.surname);
        basicInfoData.append('nickname', values.nickname);
        basicInfoData.append('headline', values.headline);
        basicInfoData.append('email', values.email);
        basicInfoData.append('birthdate', moment(values.birthdate).format(DATE_FORMAT));
        basicInfoData.append('gender', values.gender);
        basicInfoData.append('mobile', values.mobile);
        let jobClassificationList = []

        console.log(basicInfoData)
        TalentDataService.create(basicInfoData, {
          headers: {
            // Overwrite Axios's automatically set Content-Type
            'content-type': 'multipart/form-data'
          }
        })
        .then((res) => {
          values.jobClassifications.forEach(jobClassification => {
            let jobClassificationData = new FormData();
            jobClassificationData.append('talent', res.data.id);
            jobClassificationData.append('job_classification', jobClassification);
            TalentJobClassificationService.create(jobClassificationData, {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                'content-type': 'multipart/form-data'
              }
            })
          })
          
          let allSpecialisations = values.specialisations.concat(values.vocalRange)
          if(allSpecialisations.length > 0) {
            allSpecialisations.forEach(specialisation => {
              let jobClassificationWithSpecData = new FormData();
              console.log(specialisation)
              jobClassificationWithSpecData.append('talent', res.data.id);
              jobClassificationWithSpecData.append('specialisation', specialisation);
              this.getSpecialisationData(specialisation)
                .then(() => {
                  console.log(this.state.specialisation)
                  jobClassificationList.push(this.state.specialisation.job_classification);
                  jobClassificationWithSpecData.append('job_classification', this.state.specialisation.job_classification);
                })
                .then(() => {
                  TalentJobClassificationService.create(jobClassificationWithSpecData, {
                    headers: {
                      // Overwrite Axios's automatically set Content-Type
                      'content-type': 'multipart/form-data'
                    }
                  })
                })
              })            
          }


            if(values.languages.length > 0) {
              values.languages.forEach(language => {
                let languagesData = new FormData();
                languagesData.append('talent', res.data.id);
                languagesData.append('language', language.language);
                languagesData.append('proficiency', language.proficiency);
                TalentLanguageService.create(languagesData, {
                  headers: {
                    // Overwrite Axios's automatically set Content-Type
                    'content-type': 'multipart/form-data'
                  }
                })
              })
            }

            if(values.languagePairs.length > 0) {
              values.languagePairs.forEach(languagePair => {
                let languagePairsData = new FormData();
                languagePairsData.append('talent', res.data.id);
                languagePairsData.append('source_language', languagePair.source);
                languagePairsData.append('target_language', languagePair.target);
                languagePairsData.append('proficiency', languagePair.proficiency);
                TalentLanguagePairService.create(languagePairsData, {
                  headers: {
                    // Overwrite Axios's automatically set Content-Type
                    'content-type': 'multipart/form-data'
                  }
                })
              })
            }

          // Some Job Classifications might not have specialisations, so we have to post the job classification data from values.jobClassifications as well
          // To get the job classifications that haven't been posted from speacilisations, get unique 
          // let jobClassificationWithoutSpecialisations = new Set(jobClassificationList.filter(x => !values.jobClassifications.has(x)))
          // console.log(jobClassificationList)
          // console.log(values.jobClassifications)
          // console.log(jobClassificationWithoutSpecialisations)
          // jobClassificationWithoutSpecialisations.map((jobClassification) => {
          //   jobClassificationData.append('talent', res.data.id)
          //   jobClassificationData.append('job_classification', jobClassification)
          //   axios.post(TALENT_JOB_CLASSIFICATION_API_URL, jobClassificationData, {
          //     headers: {
          //       // Overwrite Axios's automatically set Content-Type
          //       'content-type': 'multipart/form-data'
          //     }
          //   })  
          // })
          if(values.projects.length > 0) {
            values.projects.forEach((project) => {
              let projectsData = new FormData();
              projectsData.append('talent', res.data.id);
              projectsData.append('project_name', project.name);
              projectsData.append('role', project.role);
              projectsData.append('company', project.company);
              projectsData.append('nature_of_project', project.natureOfProject);
              projectsData.append('start_date', moment(project.startDate,'DD/MM/YYYY').format(DATE_FORMAT));
              if(!project.isOngoing) {
                projectsData.append('end_date', moment(project.endDate,'DD/MM/YYYY').format(DATE_FORMAT));
              }
      
              projectsData.append('is_ongoing', project.isOngoing);
              projectsData.append('is_career_highlight', project.isCareerHighlight);

              TalentProjectService.create(projectsData, {
                headers: {
                  // Overwrite Axios's automatically set Content-Type
                  'content-type': 'multipart/form-data'
                }
              })  
            })
          }

          return(res.data.id)
          
        })
        .then((id) => {
          console.log("form submitted")
          setSubmitting(false);
          return(<Redirect to={{
            pathname: '/success',
            state: { 
              title: 'Form Submitted',
              subtitle: `${values.givenName+" "+values.surname} profile have been created.`,
              link1: `/talents/${id}`,
              btnText1: "View Profile",
              link1: "/talents",
              btnText1: "Back to Talent List",
            }
          }} />)
        }) 
        .catch((err) => {
          console.log(err)
        })
        
        
        
        
      }}>
        { (props, isSubmitting) => (
        <Form style={{padding: "20px 80px"}}>
          {isSubmitting ? (
            <Container>
              <h2>Form Submitted!</h2>
            </Container>
          ):(
            <Container>

              <Container>
              <Row>
              {/* <img className="avatar-preview" src={this.state.preview_avatar}></img> */}
              <Col xs="12" md="4">
                <div className="avatar-preview" style={{backgroundImage: `url(${this.state.preview_avatar})`}}></div>
                
                
                <label htmlFor="file-upload" className="btn purple-btn">
                    Choose File
                </label>
                <input hidden id="file-upload" disabled={isSubmitting} name="avatar" type="file" accept="image/png, image/jpeg" onChange={(event) => {
                    props.setFieldValue("avatar", event.target.files[0]);
                    this.handleFileUpload(event)
                  }} />
              </Col>
              <Col xs="12" md="8">
              <label>Name</label>
              <Row>
                  <Col>
                      <FormGroup>
                          <Field
                              type="text"
                              label="Given Name"
                              name="givenName"
                              id="givenName">
                              {({ field, form: { isSubmitting } }) => (<Input {...field} disabled={isSubmitting} type="text" placeholder="Given Name"/>
                              )}

                          </Field>
                          {props.errors.givenName && props.touched.givenName ? (
                            <div className="error-msg"><p>{props.errors.givenName}</p></div>
                          ) : null}
                      </FormGroup>
                  </Col>

                  <Col>
                      <FormGroup>
                          <Field
                              type="text"
                              label="Surname"
                              name="surname"
                              id="surname">
                            {({ field, form: { isSubmitting } }) => (<Input {...field} disabled={isSubmitting} type="text" placeholder="Surname"/>
                              )}

                          </Field>
                          {props.errors.surname && props.touched.surname ? (
                            <div className="error-msg"><p>{props.errors.surname}</p></div>
                          ) : null}
                      </FormGroup>
                  </Col>
              </Row> 
              <Row>
                  <Col xs="12">
                      <FormGroup>
                          <label>Nickname / Alias</label>
                          <Field
                              type="text"
                              label="Nickname"
                              name="nickname"
                              id="nickname">
                                {({ field, form: { isSubmitting } }) => (<Input {...field} disabled={isSubmitting} type="text" placeholder="Nickname"/>
                              )}
                          </Field>
                          <span className="help-text">The name you are recognized as within the industry - and is most often what will be seen in the end credits of your projects</span>

                      </FormGroup>
                  </Col>
              </Row>
              <Row>
                  <Col xs="12">
                      <FormGroup>
                          <label>Headline</label>
                          <Field
                              type="text"
                              label="Headline"
                              name="headline"
                              id="headline">
                                {({ field, form: { isSubmitting } }) => (<Input {...field} disabled={isSubmitting} type="text" placeholder="Headline"/>
                              )}
                          </Field>
                          <span className="help-text">A short sentence to describe you as a talent (e.g Voice actor and Dubbing Director)</span>

                      </FormGroup>
                  </Col>
                </Row>
              </Col>
              
        

              </Row>
                
                
                <Row>
                  <Col xs="12" md="6">
                  <label>Gender</label>
                  <Row className="mt-2">
                    <Col xs="3">
                    <Field
                      label="Gender"
                      name="gender"
                      value="female"
                      type="radio">

                        {({ field, form: { isSubmitting } }) => (
                        <div className="custom-control custom-radio mb-3">
                          <input
                            {...field}
                            disabled={isSubmitting}
                            className="custom-control-input"
                            id="female"
                            type="radio"
                          />
                          <label className="custom-control-label" htmlFor="female">
                            Female
                          </label>
                      </div>
                      )}

                      </Field>
                    </Col>
                    <Col xs="3">
                    <Field
                      label="Gender"
                      name="gender"
                      value="male"
                      type="radio">
                        {({ field, form: { isSubmitting } }) => (
                        <div className="custom-control custom-radio mb-3">
                          <input
                            {...field}
                            disabled={isSubmitting}
                            className="custom-control-input"
                            id="male"
                            type="radio"
                          />
                          <label className="custom-control-label" htmlFor="male">
                            Male
                          </label>
                      </div>
                      )}
                    </Field>
                    </Col>
                    
                    <Col xs="3">
                    <Field
                      label="Gender"
                      name="gender"
                      value="other"
                      type="radio">
                        {({ field, form: { isSubmitting } }) => (
                        <div className="custom-control custom-radio mb-3">
                          <input
                            {...field}
                            disabled={isSubmitting}
                            className="custom-control-input"
                            id="other"
                            type="radio"
                          />
                          <label className="custom-control-label" htmlFor="other">
                            Other
                          </label>
                      </div>
                      )}
                    </Field>
                    </Col>
                    </Row>
                    {props.errors.gender && props.touched.gender ? (
                      <div className="error-msg"><p>{props.errors.gender}</p></div>
                    ) : null}
                  </Col>

                  <Col xs="12" md="6">
                  <FormGroup>
                    <label>Birthdate</label>

                    <Field
                      name="birthdate"
                      type="date"
                      label="Birthdate"
                      component={FormikDateTime}
                      />
                    </FormGroup>
                  </Col>
              </Row>
              
              <Row>
                  <Col xs="12" md="6">
                    <FormGroup>
                      <label>Email</label>
                      <Field
                          type="email"
                          label="Email"
                          name="email"
                          id="email">
                            {({ field, form: { isSubmitting } }) => (
                              <InputGroup >
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="ni ni-email-83" />
                                    </InputGroupText>
                                </InputGroupAddon>
                              <Input {...field} disabled={isSubmitting} type="email" placeholder="Email" />
                              </InputGroup>
                          )}
                        </Field>

                        {props.errors.email && props.touched.email ? (
                            <div className="error-msg"><p>{props.errors.email}</p></div>
                          ) : null}
                    </FormGroup>
                  </Col>
              
                  <Col xs="12" md="6">
                    <FormGroup>
                      <label>Mobile number</label>
                      <Field
                          type="text"
                          label="Mobile Number"
                          name="mobile"
                          id="mobile">
                            {({ field, form: { isSubmitting } }) => (
                              <InputGroup >
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="ni ni-mobile-button" />
                                    </InputGroupText>
                                </InputGroupAddon>
                              <Input {...field} disabled={isSubmitting} type="text" placeholder="Mobile number" />
                            </InputGroup>
                          )}
                      </Field>
                    </FormGroup>
                  
                  </Col>
            </Row>
            
          </Container>
          <Container style={{ marginTop: "50px" }}>
              <h3>Job Classification</h3>
              <span className="subtext">Please select job classification(s) that applies to you</span>
              <Row className="mt-4">
                <Col>
                  <FieldArray
                  name="jobClassifications"
                  render={arrayHelpers => (
                    <div>
                      {this.state.jobClassifications.map(jobClassification => (
                        <div key={jobClassification.id} className="custom-control custom-checkbox mb-3">
                          
                            <input
                              className="custom-control-input"
                              name="jobClassifications"
                              type="checkbox"
                              value={jobClassification.id}
                              id={jobClassification.id}
                              checked={props.values.jobClassifications.includes(jobClassification.id)}
                              onChange={e => {
                                if (e.target.checked) arrayHelpers.push(jobClassification.id);
                                else {
                                  const idx = props.values.jobClassifications.indexOf(jobClassification.id);
                                  arrayHelpers.remove(idx);
                                }
                              }}
                            />
                          <label className="custom-control-label" htmlFor={jobClassification.id}>
                            {jobClassification.name}
                          </label>
                        </div>

                      ))}
                    </div>
                  )}
                />
                </Col>
              </Row>
              
          </Container>
          <Container style={{ marginTop: "50px" }}>

            {/* Translator Specialisation */}
            {(props.values.jobClassifications.includes(2)) && (
                <FormGroup>
                  <h4>Translator</h4>
                  <span className="subtext">Please specify languages you can translate to/from:</span>
                  <FieldArray
                    name="languagePairs"
                    render={arrayHelpers => (
                      <div>
                        {props.values.languagePairs.map((pair, index) => (
                          <div key={index}>
                            <Row>
                            <Col xs="12" md="3">
                              <Field
                                  component={TextField}
                                  type="text"
                                  name={`languagePairs.${index}.source`}
                                  select
                                  variant="standard"
                                  helperText="Source Language"
                                  margin="none"
                                  className="select-field"
                                  InputLabelProps={{
                                      shrink: true,
                                  }}>
                                  {this.state.languages.map(option => (
                                  <MenuItem className="select-item" key={option.id} value={option.name}>
                                      {option.name}
                                  </MenuItem>
                                  ))}
                              </Field>
                          </Col>

                          <Col xs="12" md="3">
                              <Field
                                  component={TextField}
                                  type="text"
                                  name={`languagePairs.${index}.target`}
                                  select
                                  variant="standard"
                                  helperText="Target Language"
                                  margin="none"
                                  className="select-field"
                                  InputLabelProps={{
                                      shrink: true,
                                  }}>
                                  {this.state.languages.map(option => (
                                  <MenuItem className="select-item" key={option.id} value={option.name}>
                                      {option.name}
                                  </MenuItem>
                                  ))}
                              </Field>
                          </Col>
                          <Col xs="12" md="3">
                                  <Field
                                      component={TextField}
                                      type="text"
                                      name={`languagePairs.${index}.proficiency`}
                                      select
                                      variant="standard"
                                      helperText="Proficiency Level"
                                      margin="none"
                                      className="select-field"
                                      InputLabelProps={{
                                          shrink: true,
                                      }}>
                                      {this.state.languageProficiencies.map(option => (
                                      <MenuItem className="select-item" key={option.id} value={option.id}>
                                          {option.name}
                                      </MenuItem>
                                      ))}
                                  </Field>
                                </Col>
                          <Col xs="12" md="2">
                            <DeleteIcon 
                            onClick={() => arrayHelpers.remove(index)}
                            style={{fontSize: "1.2em", cursor: "pointer"}}/>
                      
                          </Col>
                          </Row>
                            
                            
                          </div>
                        ))}
                        
                        <Button 
                          className="btn-icon btn-3" 
                          color="neutral" 
                          type="button" 
                          style={{marginTop: "20px"}}
                          onClick={() => arrayHelpers.push({ source: '', target: '', proficiency: '' })}>
                          <span className="btn-inner--icon">
                            <AddIcon style={{fontSize: "0.8em"}}/>
                          </span>
                          <span className="btn-inner--text" style={{fontSize: "0.8em"}}>Language Pair</span>
                        </Button>
                      </div>
                    )}
                  />
                </FormGroup>  
              )}

              {/* Director Specialisation */}
              {(props.values.jobClassifications.includes(1)) && (
              <FormGroup>
                  <h4>Director</h4>                
                            
                  <Row>
                    <Col>
                      {this.createCheckboxGroup(
                        this.state.specialisations.filter((specialisation) => specialisation.job_classification === 1),
                        props.values.specialisations,
                        "specialisations")}
                    </Col>
                  </Row>  

                </FormGroup>  
              )}

              {/* Script / Copywriter Specialisation */}
              {(props.values.jobClassifications.includes(3)) && (
              <FormGroup>
                  <h4>Script / Copywriter</h4>                
                  <Row>
                    <Col>
                      {this.createCheckboxGroup(
                        this.state.specialisations.filter((specialisation) => specialisation.job_classification === 3),
                        props.values.specialisations,
                        "specialisations")}
                    </Col>
                  </Row>       
                          

                </FormGroup>  
              )}

              {(props.values.jobClassifications.includes(4)) && (
              <FormGroup>
                  <h4>Voice Actor</h4>                
                        
                          <Row>
                            <Col xs="12" md="4">
                            <label><b>Specialisation</b></label>
                            {this.createCheckboxGroup(
                              this.state.specialisations.filter((specialisation) => specialisation.job_classification === 4),
                              props.values.specialisations,
                              "specialisations")}
                            </Col>
                          
                        </Row>
                  </FormGroup>
              )}       
              {(props.values.jobClassifications.includes(5)) && (
                <FormGroup>
                  <h4>Singer / Vocalist</h4>
                  <Row>
                    <Col xs="12" md="5">
                        <Field
                            component={TextField}
                            type="text"
                            name="vocalRange"
                            select
                            variant="standard"
                            helperText="Please select your Vocal Range"
                            margin="none"
                            className="select-field"
                            InputLabelProps={{
                                shrink: true,
                            }}>
                            {this.state.specialisations
                            .filter((specialisation) => specialisation.job_classification === 5)
                            .map(option => (
                            <MenuItem className="select-item" key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
                            ))}
                        </Field>
                    </Col>
                  </Row>
                </FormGroup>
              )}

          {(props.values.jobClassifications.includes(7)) && (
              <FormGroup>
                  <h4>Dancer</h4>                
                        
                          <Row>
                            <Col xs="12" md="4">
                            <label><b>Known Styles</b></label>
                            {this.createCheckboxGroup(
                              this.state.specialisations.filter((specialisation) => specialisation.job_classification === 7),
                              props.values.specialisations,
                              "specialisations")}
                            </Col>
                          
                        </Row>
                  </FormGroup>
              )} 

              {(props.values.jobClassifications.includes(8)) && (
              <FormGroup>
                  <h4>Actor</h4>                
                        
                          <Row>
                            <Col xs="12" md="4">
                            <label><b>Specialisation</b></label>
                            {this.createCheckboxGroup(
                              this.state.specialisations.filter((specialisation) => specialisation.job_classification === 8),
                              props.values.specialisations,
                              "specialisations")}
                            </Col>
                          
                        </Row>
                  </FormGroup>
              )} 

          <Row>
              <Col xs="12" md="10">
                  <label><b>Please specify languages you can speak:</b></label>
                  <FieldArray
                    name="languages"
                    render={arrayHelpers => (
                      <div>
                        {props.values.languages.map((language, index) => (
                            <div key={index}>
                              <Row>
                                <Col>
                                  <Field
                                      component={TextField}
                                      type="text"
                                      name={`languages.${index}.language`}
                                      select
                                      variant="standard"
                                      helperText="Choose a Language"
                                      margin="none"
                                      className="select-field"
                                      InputLabelProps={{
                                          shrink: true,
                                      }}>
                                      {this.state.languages.map(option => (
                                      <MenuItem className="select-item" key={option.id} value={option.name}>
                                          {option.name}
                                      </MenuItem>
                                      ))}
                                  </Field>
                                </Col>
                                <Col>
                                  <Field
                                      component={TextField}
                                      type="text"
                                      name={`languages.${index}.proficiency`}
                                      select
                                      variant="standard"
                                      helperText="Proficiency Level"
                                      margin="none"
                                      className="select-field"
                                      InputLabelProps={{
                                          shrink: true,
                                      }}>
                                      {this.state.languageProficiencies.map(option => (
                                      <MenuItem className="select-item" key={option.id} value={option.id}>
                                          {option.name}
                                      </MenuItem>
                                      ))}
                                  </Field>
                                </Col>
                                <Col>
                                  <DeleteIcon 
                                    onClick={() => arrayHelpers.remove(index)}
                                    style={{fontSize: "1.2em", cursor: "pointer"}}/>
                                </Col>
                                
                              </Row>
                            
                              
                            </div>
                        ))}
                      
                      <Button 
                        className="btn-icon btn-3" 
                        color="neutral" 
                        type="button" 
                        style={{marginTop: "20px"}}
                        onClick={() => arrayHelpers.push({language: '', proficiency: ''})}>
                        <span className="btn-inner--icon">
                          <AddIcon style={{fontSize: "0.8em"}}/>
                        </span>
                        <span className="btn-inner--text" style={{fontSize: "0.8em"}}>Language</span>
                      </Button>
                    </div>
                  )}
                  />
                  
                </Col>
              </Row>
          </Container>

          

          <Container style={{ marginTop: "50px" }}>
            <h3>Your Projects</h3>
            <span className="subtext">Please add your past and/or current projects that you'd like to include in your profile.</span>

          <FormGroup>
                  
                  <FieldArray
                    name="projects"
                    render={arrayHelpers => (
                      <div style={{marginTop: "20px"}}>
                        {props.values.projects.map((pair, index) => (
                          <div key={index} style={{marginTop: "10px"}}>
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                              <div>
                                <h4>Project {index+1}: {props.values.projects[index].name}</h4>
                              </div>

                              
                              {/* <DeleteIcon 
                              onClick={() => arrayHelpers.remove(index)}
                              style={{fontSize: "1.2em", cursor: "pointer"}}/> */}
                              <Button 
                                className="btn-icon btn-3" 
                                color="danger" 
                                type="button" 
                                onClick={() => arrayHelpers.remove(index)}>
                                <span className="btn-inner--icon">
                                  <DeleteIcon style={{fontSize: "1.2em"}}/>
                                </span>
                                <span className="btn-inner--text" style={{fontSize: "0.7em"}}>Remove</span>
                              </Button>
                              
                            </div>

                            <Row>
                            
                            <Col xs="12">
                              <FormGroup>
                                <label>Project Name / Alias</label>
                                <Field
                                  type="text"
                                  label="Project Name"
                                  name={`projects.${index}.name`}>
                                  {({ field, form: { isSubmitting } }) => (<Input {...field} disabled={isSubmitting} type="text" placeholder="Project Name" />
                                  )}
                                </Field>
                              </FormGroup>
                          </Col>
                          </Row>

                          <Row>
                          <Col xs="12">
                            <FormGroup>
                              <label>Position / Role</label>
                              <Field
                                type="text"
                                label="Position / Role"
                                name={`projects.${index}.role`}>
                                {({ field, form: { isSubmitting } }) => (<Input {...field} disabled={isSubmitting} type="text" placeholder="Position / Role" />
                                )}
                              </Field>
                            </FormGroup>
                          </Col>
                          
                          </Row>

                          <Row>
                          <Col xs="12">
                            <FormGroup>
                              <label>Company</label>
                              <Field
                                type="text"
                                label="Company"
                                name={`projects.${index}.company`}>
                                {({ field, form: { isSubmitting } }) => (
                                  <InputGroup>
                                      <InputGroupAddon addonType="prepend">
                                          <InputGroupText>
                                              <i className="ni ni-building" />
                                          </InputGroupText>
                                      </InputGroupAddon>
                                      <Input {...field} disabled={isSubmitting} type="text" placeholder="Company" />
                                  </InputGroup>
                                  )}
                              </Field>
                            </FormGroup>
                          </Col>
                          
                          </Row>

                          <Row>
                          <Col xs="12">
                            
                            <FormGroup>
                              <label>Nature of Project</label>
                              <Field
                                type="text"
                                label="Position / Role"
                                name={`projects.${index}.natureOfProject`}>
                                {({ field, form: { isSubmitting } }) => (<Input {...field} disabled={isSubmitting} type="text" placeholder="Nature of Project" />
                                )}
                              </Field>
                              <span className="help-text">Example: Tagalog dubbing, English subtitling, German voice acting, etc.</span>
                            </FormGroup>
                          </Col>
                          
                          </Row>
                            
                          <Row>
                            <Col xs="12" md="5">
                              <FormGroup>
                                <label>Start Date</label>

                                <Field
                                  name={`projects.${index}.startDate`}
                                  type="date"
                                  label="Start date"
                                  component={FormikDateTime}
                                  />
                              </FormGroup>
                            </Col>
                            <Col xs="12" md="5">
                              <FormGroup>
                                <label>End Date</label>

                                <Field
                                  name={`projects.${index}.endDate`}
                                  type="date"
                                  label="End date"
                                  InputLabelProps={{disabled: props.values.projects[index].isOngoing}}
                                  component={FormikDateTime}
                                  />
                              </FormGroup>
                            </Col>
                            <Col xs="12" md="2">
                              <FormGroup style={{marginTop:"40px"}}>
                                <Field
                                  name={`projects.${index}.isOngoing`}
                                  type="checkbox">
                                    {({ field, form: { isSubmitting } }) => (
                                    <div className="custom-control custom-checkbox mb-3">
                                      <input
                                        {...field}
                                        disabled={isSubmitting}
                                        className="custom-control-input"
                                        name={`projects.${index}.isOngoing`}
                                        type="checkbox"
                                        id={`isOngoing${index}`} />

                                      <label className="custom-control-label" htmlFor={`isOngoing${index}`}>
                                        Ongoing project
                                      </label>
                                    </div>
                                    )}
                                </Field>
                              </FormGroup>
                            </Col>
                          </Row>
                          
                          <Row>
                            <Col xs="12" md="4">
                              <FormGroup style={{marginTop:"20px"}}>
                                <Field
                                  name={`projects.${index}.isCareerHighlight`}
                                  type="checkbox">
                                    {({ field, form: { isSubmitting } }) => (
                                    <div className="custom-control custom-checkbox mb-3">
                                      <input
                                        {...field}
                                        disabled={isSubmitting}
                                        className="custom-control-input"
                                        name={`projects.${index}.isCareerHighlight`}
                                        type="checkbox"
                                        id={`isCareerHighlight${index}`} />

                                      <label className="custom-control-label" htmlFor={`isCareerHighlight${index}`}>
                                        Add as a <b>Career Highlight</b>
                                      </label>
                                    </div>
                                    )}
                                </Field>
                              </FormGroup>
                            </Col>
                          </Row>
                            
                          </div>
                        ))}
                        
                        <Button 
                          className="btn-icon btn-3" 
                          color="neutral" 
                          type="button" 
                          style={{marginTop: "20px"}}
                          onClick={() => arrayHelpers.push({
                            name: "",
                            role: "",
                            company: "",
                            natureOfProject: "",
                            startDate: "",
                            endDate: "",
                            isOngoing: false,
                            isCareerHighlight: false
                          })}>
                          <span className="btn-inner--icon">
                            <AddIcon style={{fontSize: "0.8em"}}/>
                          </span>
                          <span className="btn-inner--text" style={{fontSize: "0.8em"}}>Project</span>
                        </Button>
                      </div>
                    )}
                  />
                </FormGroup>
          </Container>
                
          <Container style={{ marginTop: "50px" }}>
          
              <Button color="primary" type="submit" disabled={isSubmitting}>Submit</Button>
              
          </Container>
          {/* <pre>{JSON.stringify(props.values, null, 2)}</pre>  */}
          {/* <ResultModal title="Inputted data" show={true}>
              <pre>{JSON.stringify(props.values, null, 2)}</pre>         
          </ResultModal> */}
            </Container>
          )}
          
        </Form>
      )}
      
      </Formik>
      
      </div>
  )}
}


export default TalentForm;