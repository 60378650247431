import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// Data services
import CompanyDataService from '../services/company.service'

const initialState = {
    data: [],
    status: 'idle',
    error: null
}

/**
   * Get all Companies
   */
  export const fetchAllCompanies = createAsyncThunk('company/fetchAllCompanies', async (token) => {
    const response = await CompanyDataService.getAll(token)
    return response.data
})




export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAllCompanies.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchAllCompanies.fulfilled]: (state, action) => {
      state.data = action.payload.reverse()
      state.status = 'succeeded'
    },
    [fetchAllCompanies.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    }
  }
});

export const selectAllCompanies = (state) => state.company.data


export default companySlice.reducer
