
import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import JobClassificationList from '../../common/JobClassificationList';

import {
    Row,
    Col
} from "reactstrap";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

import './TalentProfile.css'


export default class ProfileHeader extends Component {

    render() {
        var avatar = this.props.avatar ||  "/blank-profile-picture.png";
        return(
            <div className="profile-header-container">
                <Row className="profile-header">
                    <Col xs="12" lg="5" xl="4">
                        <div 
                            className="img-fluid rounded-circle shadow-lg profile-pic avatar position-relative"
                            style={{backgroundImage: `url(${avatar})`}}>
                            {/* <div className="change-avatar position-absolute w-2 h-2 rounded-circle">
                                <EditOutlinedIcon style={{color: "#e5e8f0"}}/>
                            </div> */}
                            
                        </div>
                    </Col>

                    <Col xs="12" className="d-md-none">
                        <div className="btn-wrapper">
                            <Link to="/profile/edit">
                                <button className="btn blue-btn">
                                    Edit
                                </button>
                            </Link>
                            
                        </div>
                
                    </Col>
                     

                    <Col xs="12" lg="5" xl="5" className="profile-header-text">
                        <Row>
                            <Col>
                                <h2>{this.props.displayName}</h2>
                                <span>{this.props.subName}</span>
                            </Col>
                        </Row>
                        <br></br>
                        
                        {/* <div className="stats">
                            <div className="stats-item"><span className="stats-num">{this.props.connections}</span>   Connections</div>
                            <div className="stats-item"><span className="stats-num">{this.props.projects}</span>   Projects</div>
                        </div> */}
                        <span>{this.props.headline}</span>
                        {/* <Row className="contacts">
                            <Col className="pl-0" sm="12" md="5">
                                <MailTwoTone style={{fontSize: "1.4em", marginRight: "10px"}} twoToneColor="#cb1670"/>
                                <span> {this.props.email}</span>
                            </Col>

                            <Col sm="12" md="4">
                                <MobileTwoTone style={{fontSize: "1.4em", marginRight: "10px"}} twoToneColor="#4d6fff"/>
                                <span> {this.props.mobile}</span>
                            </Col>
                            
                            
                        </Row> */}

                        <Row className="pl-2 mt-2">
                            <JobClassificationList data={this.props.jobs} bgColor="rgba(234,76,137,0.9)" color="#fff"/>
                        </Row>
                    </Col>
                    
                    <Col  lg="2" xl="3" className="d-none d-md-block">
                        <div className="btn-wrapper">
                            <Link to="/profile/edit">
                                <button className="btn blue-btn-reversed">
                                    Edit
                                </button>
                            </Link>
                            {/* <Button color="info" type="button">
                                Connect
                            </Button>

                            <Button color="default" type="button">
                                Message
                            </Button>                 */}
                        </div>
                
                    </Col>

                </Row> 
            </div>
            
        )
    }
    
}
