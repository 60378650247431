import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { useSelector } from 'react-redux'
import { selectPublishedJobPosts } from '../../../state/jobPostSlice'


// Icons
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { RoundIcon } from '../../../constants'
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PeopleIcon from '@material-ui/icons/People';

// Custom UI Components
import Header from '../../common/Header/Header';
import JobPostCard from '../../common/JobPost/JobPostCard'
import StatsCard from '../../common/Card/StatsCard'
import EmptyCard from '../../common/Card/EmptyCard'


// UI Libraries
import { Skeleton, Empty } from 'antd';

// reactstrap components
import {
    Row,
    Col,
    Container
  } from "reactstrap"

import './AdminHome.css'

/**
 * Home page for Talents
 */
const AdminHome = (props) => {

    // const [jobPosts, setJobPosts] = useState([])
    const [projects, setProjects] = useState([])

    const [stats, setStats] = useState([])
    
    // Get only the 3 most recent job posts
    const publishedJobPosts = useSelector(selectPublishedJobPosts)
    const status = useSelector((state) => state.common.status)

    useEffect(() => {
        // Add Stats data for projects, job listings, and number of talents onboarded
        setStats([
            {
                name: "Active Job Listings",
                icon: <RoundIcon bgColor= "#00bcdf" icon = {<AssignmentIcon style={{color: "rgba(255, 255, 255, 0.4)"}}/>} />,
                value: publishedJobPosts.length
            },
            {
                name: "Active Projects",
                icon: <RoundIcon bgColor= "#ed5e94" icon = {<AssessmentIcon style={{color: "rgba(255, 255, 255, 0.4)"}}/>} />,
                value: 0
            },
            {
                name: "Talents",
                icon: <RoundIcon bgColor= "#4d6fff" icon = {<PeopleIcon style={{color: "rgba(255, 255, 255, 0.4)"}}/>} />,
                value: 30
            }
        ])
    }, [publishedJobPosts, setStats])
   
    // Show loading elements when talent data is not yet fetched
    var header = `Hi, Production Team`
    var subheader = <p>Welcome to your Sleigh admin account</p>
    var headerBg = <div className="bg-illustration">
        <img src={require("../../../assets/background/bg-pink.png")}></img>
    </div>
    // if(talent.basicData.given_name === undefined) {
    //     header = <Skeleton.Input style={{ width: 200 }} active={true} />
    //     subheader = <Skeleton.Input style={{ width: 300 }} active={true} />
    //     headerBg = <></>
    // }

    return(
        <div className="admin-home-container">
            {/* {headerBg} */}
            <Header 
                title={header}
                subtext={subheader}
            />
            <Container className="content-container">
                    <Row>
                        {stats.length > 0 ? (stats.map(stat => {
                            // Render stat cards
                            return(
                                <Col md="3" className="p-0 mr-2 mt-2">
                                    <StatsCard 
                                    title={<h1><b>{stat.value}</b></h1>} 
                                    details={<span className="p-2 text-muted">{stat.name}</span>}
                                    icon={stat.icon}/>
                                </Col>
                                
                            )
                        })

                        ) : (
                            // Loading empty cards
                            <>
                            <Col md="3" className="p-0 mr-2 mt-2">
                                <EmptyCard />
                            </Col>
                            <Col md="3" className="p-0 mr-2 mt-2">
                                <EmptyCard />
                            </Col>
                            <Col md="3" className="p-0 mr-2 mt-2">
                                <EmptyCard />
                            </Col>
                            </>
                        )}
                    </Row>
                
                    {/* Active/Published Job Listings */}
                    <Row className="mt-5">

                        <div style={{width: "100%"}}>
                            <div className="spaced-between">
                                <h2>Active Job Listings</h2>
                                <Link to="/job-posts"><span>See more</span></Link>
                            </div>
                            <div className="grid-container mt-3">
                            {publishedJobPosts.length > 0 ? (publishedJobPosts.map((job) => {
                                var bgColor = "#fff", color = "#35424a"; 
                                var applicantsNum = 12;
                                var viewsCount = 30;

                                return(
                                        <JobPostCard 
                                            title={job.job_title}
                                            // subtext={`${projSubtype.data.name} | ${empType.data.name}`}
                                            company={job.company_id}
                                            bgColor={bgColor}
                                            textColor={color}
                                            width="100%"
                                            labelBottom={
                                                <Link className="link" to={`/job-posts/${job.id}`}>
                                                    <button className="btn rounded m-0 mb-2 pt-1 pb-1 pr-3 pl-3 dark-blue-btn">View</button>
                                                </Link>
                                            }
                                            extra={
                                            <>
                                                {/* <div className="d-flex mt-2">
                                                    <div className="mr-3"><VisibilityIcon style={{color: '#ed5e94', fontSize: '1.2em'}}/> <b>{viewsCount}</b> Views</div>
                                                    <div><AssignmentTurnedInIcon style={{color: '#ed5e94', fontSize: '1.2em'}} /> <b>{applicantsNum}</b> Applicants</div>
                                                </div> */}
                                                <div className="mt-2 text-muted w-100 text-xs">{moment(job.dt_published, "YYYY-MM-DD").fromNow()}</div>

                                            </>}
                                        />
                                )
                            })) : (
                                // If it's loading, show loading card component, if it's not it means there is no data to load
                                status === 'loading' ? (  
                                    <>
                                        <EmptyCard />
                                        <EmptyCard />
                                        <EmptyCard />
                                     </>
                                 ) : (
                                    <>
                                        <div></div>
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="You have no active job posts"/>
                                        <div></div>
                                    </>
                                 )
                            )}
                            </div>
                        </div>
                    
                </Row>
                
                {/* Active Projects */}
                <Row className="mt-5 mb-5">

                        <div style={{width: "100%"}}>
                            <div className="spaced-between">
                                <h2>Active Projects</h2>
                                <Link to="/projects"><span>See all</span></Link>
                            </div>
                            <div className="grid-container mt-3">
                            {projects.length > 0 ? (projects.map((job) => {
                                var bgColor = "#EFF1F6", color = "#35424a"; 
                                

                                return(
                                    <Link className="link" to='/projects'>
                                        
                                    </Link>
                                    
                                )
                            })) : (
                                //If it's loading, show loading card component, if it's not it means there is no data to load
                                status === 'loading' ? (  
                                    <>
                                        <EmptyCard />
                                        <EmptyCard />
                                        <EmptyCard />
                                     </>
                                 ) : (
                                    <>
                                        <div></div>
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="You have no active projects"/>
                                        <div></div>
                                    </>
                                 )
                            
                            )}
                            </div>
                        </div>
                    
                </Row>
            </Container>
        </div>
    )
    
}

export default AdminHome
