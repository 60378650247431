import React, { useState } from "react";
import { Field, ErrorMessage } from "formik";


import { MailOutlined, LockOutlined } from '@ant-design/icons';

import {
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup
  } from "reactstrap";

/**
 * First page of sign up form
 * @author Nadia Mayangputri
 */
const SignUpStep1 = () => { 

  return (
    <>
     <div className="mb-4">
        <h1>Sign Up</h1>
    </div>
    <label>Email</label>
        <Field
            type="email"
            label="Email"
            name="email"
            id="email">
            {({ field, form: { isSubmitting } }) => (
                <InputGroup >
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                        <MailOutlined style={{color: "#b5b5b5"}} />
                    </InputGroupText>
                </InputGroupAddon>
                <Input {...field} disabled={isSubmitting} type="email" placeholder="Email" />
                </InputGroup>
            )}
        </Field>
        <ErrorMessage name="email" component="div"/>
        <br></br>
        <label>Password</label>
        <Field
            type="password"
            label="Password"
            name="password"
            id="password">
            {({ field, form: { isSubmitting } }) => (
                <InputGroup >
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                        <LockOutlined style={{color: "#b5b5b5"}}/>
                    </InputGroupText>
                </InputGroupAddon>
                <Input {...field} disabled={isSubmitting} type="password" placeholder="Password"/>
                </InputGroup>
            )}
        </Field>

        <ErrorMessage name="password" component="span" />
      
    </>
  );
};

export default SignUpStep1;
