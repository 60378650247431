import React, { useEffect } from 'react';
import {
    InputGroupAddon,
    InputGroupText,
    InputGroup
  } from "reactstrap";


// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
import moment from 'moment';

const DATE_FORMAT = 'DD/MM/YYYY'

const FormikDateTime = ({ field, form, timeFormat, ...props }) => {
  let isDisabled;
  if(props.InputLabelProps != undefined) isDisabled = props.InputLabelProps.disabled;

  const onFieldChange = value => {
    let dateValue = value;

    // if the date field isn't in a valid date format,
    // react-datetime's onChange handler returns a string
    // otherwise it returns a moment object
    // this is why we can't override DateTime's onChange
    // prop with Formik's field.onChange
    if (value instanceof moment) {
      dateValue = moment(value).format(DATE_FORMAT);
    }

    form.setFieldValue(field.name, dateValue);
  }

  const onFieldBlur = () => {
    form.setFieldTouched(field.name, true);
  }

  return (
    <InputGroup>
        <InputGroupAddon addonType="prepend">
            <InputGroupText>
                <i className="ni ni-calendar-grid-58" />
            </InputGroupText>
        </InputGroupAddon>
        <ReactDatetime
        dateFormat={DATE_FORMAT}
        timeFormat={false}
        id={field.name}
        name={field.name}
        inputProps={{placeholder: "Select a date", disabled: isDisabled}}
        onChange={onFieldChange}
        onBlur={onFieldBlur}
        viewMode='years'
        value={field.value}
        />
    </InputGroup>
  );
}

export default FormikDateTime;