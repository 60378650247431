import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {capitalizeFirstLetter} from '../constants'

// Data services
import TalentLanguagePairService from "../services/talentLanguagePair.service";


const initialState = {
  data: [],
  status: 'idle',
  error: null
}

export const fetchTalentLanguagePair = createAsyncThunk('talent/fetchTalentLanguagePair', async (token) => {
  const response = await TalentLanguagePairService.getAll(token)
  var languagePairs = []

  for(let i = 0; i < response.data.length; i++) {
    var currData = response.data[i];
    var source = currData.source_language;
    var target = currData.target_language;
    var proficiency = capitalizeFirstLetter(currData.proficiency.toLowerCase());

    languagePairs = [...languagePairs, {
      "source": source, "target": target, "proficiency": proficiency
  }]
  }
  
  return languagePairs;
})

// Talent LanguagePait slice
export const talentLanguagePairSlice = createSlice({
  name: 'talentLanguagePair',
  initialState,
  reducers: {
    resetTalentLanguagePair: (state) => {
      state.data = initialState.data
    }
  },
  extraReducers: {
    [fetchTalentLanguagePair.pending]: 
      (state, action) => {
      state.status = 'loading'
    },
    [fetchTalentLanguagePair.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    },
    [fetchTalentLanguagePair.fulfilled]: (state, action) => {
      state.data = action.payload
      state.status = 'succeeded'
    }
    
  }
});


export default talentLanguagePairSlice.reducer

export const { resetTalentLanguagePair } = talentLanguagePairSlice.actions;

export const selectTalentLanguagePair = (state) => state.talentLanguagePair.data

  