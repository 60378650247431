/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Original Code by Creative Tim
* Modified by Nadia Mayangputri

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";


// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col
} from "reactstrap";

import './Sidebar.css'
import Topbar from '../Topbar/Topbar'
import talentJobClassificationService from "../../../services/talentJobClassification.service";

var ps;

class Sidebar extends React.Component {
  state = {
    collapseOpen: false
  };
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false
    });
  };
  // creates the links that appear in the left menu / Sidebar
  createLinks = routes => {
    return routes.map((prop, key) => {
      return (
        <NavItem key={key}>
          <NavLink
            exact
            to={prop.path}
            tag={NavLinkRRD}
            onClick={this.closeCollapse}
            activeClassName="active"
            className={this.props.userType.toLowerCase()}
          >
            <i>{prop.icon}</i>
            <span className="pt-2">{prop.name}</span>
          </NavLink>
        </NavItem>
      );
    });
  };
  render() {
    const { bgColor, routes, logo } = this.props;
    let navbarBrandProps, user;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: "_blank"
      };
    }

    if(this.props.userType === 'TALENT') {
      user = "Talent"
    } else if(this.props.userType === 'PRODUCTION_MANAGER') {
      user = "Team"
    }
    return (
      <Navbar
        className="navbar-vertical fixed-left navbar-light bg-white"
        expand="lg"
        id="sidenav-main"
      >
        <Container fluid>
          
          {/* Brand */}
          {logo ? (
              <div className="d-none d-lg-block" style={{width: "100px"}}>
                  <img
                    alt={logo.imgAlt}
                    src={logo.imgSrc}
                    style={{width: "100px"}}
                />
                </div>
          ) : null}
          {/* Toggler */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={this.toggleCollapse}
          >
            <span className="navbar-toggler-icon" />
          </button>
          {/* User */}
          <div className="d-lg-none">
            <Topbar />
          </div>
          
          {/* Collapse */}
          <Collapse navbar isOpen={this.state.collapseOpen}>
            {/* Collapse header */}
            <div className="navbar-collapse-header d-lg-none">
              <Row>
                {logo ? (
                  <Col className="collapse-brand">
                    {logo.innerLink ? (
                      <Link to={logo.innerLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </Link>
                    ) : (
                      <a href={logo.outterLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </a>
                    )}
                  </Col>
                ) : null}
                <Col className="collapse-close">
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={this.toggleCollapse}
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            
            {/* Navigation */}
            <h6 className="navbar-heading text-muted">Welcome {user}!</h6>

            <Nav navbar className="mt-0">{this.createLinks(routes)}</Nav>
            {/* Divider */}
            <hr className="my-3" />
            {/* Heading */}
            {/* <h6 className="navbar-heading text-muted">Admin</h6> */}
            {/* Navigation */}
            <Nav className="mb-md-3 mt-0" navbar>
                {this.props.navSubItems.map((navSubItem, index) =>{
                    return(
                        <NavItem key={index}>
                            <NavLink to={navSubItem.path} tag={NavLinkRRD} className={this.props.userType.toLowerCase()}>
                                <i>{navSubItem.icon}</i>
                                <span className="pt-2">{navSubItem.name}</span>
                            </NavLink>
                        </NavItem>
                    )
                })}

            </Nav>
            <Nav className="mb-md-3" navbar>
              <NavItem className="active-pro active">
                {/* <NavLink onClick={e => {
                  e.preventDefault()
                  dispatch(logout())
                  history.push('/')
                  }}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </NavLink> */}
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}]
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired
  })
};

export default Sidebar;

