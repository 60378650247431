import React from 'react'
import { Container } from 'reactstrap'
import { Link } from 'react-router-dom'

import './AuthComponents.css'


const SignInOptions = () => (
    <Container className="m-auto">
        <h1 className="text-center">Sign in</h1>
        <div className="flex-centered">
        <div className="signin-option">
            <Link to='/signin/talent'>
            <img className="d-none d-lg-block" style={{width: 300}} src={require('../../../assets/graphics/Composer-rafiki.png')}></img>
            <img className="d-none d-md-block d-lg-none" style={{width: 220}} src={require('../../../assets/graphics/Composer-rafiki.png')}></img>
            <img className="d-md-none" style={{width: 170}} src={require('../../../assets/graphics/Composer-rafiki.png')}></img>
            <h2 className="text-center text-white font-weight-light">Talent</h2>
            </Link>
        </div>
        <div className="signin-option">
            <Link to='/signin/admin'>
            <img className="d-none d-lg-block" style={{width: 300}} src={require('../../../assets/graphics/Scrum board-rafiki.png')}></img>
            <img className="d-none d-md-block d-lg-none" style={{width: 220}} src={require('../../../assets/graphics/Scrum board-rafiki.png')}></img>
            <img className="d-md-none" style={{width: 170}} src={require('../../../assets/graphics/Scrum board-rafiki.png')}></img>
            <h2 className="text-center text-white font-weight-light">Production Team</h2>
            </Link>
        </div>
        </div>

    </ Container>
                     

)

export default SignInOptions;