import http from "../http-common";

const url = "api/core/gender/"

class GenderDataService {
  getAll(token) {
    http.defaults.headers['Authorization'] = `Token ${token}`
    return http.get(url);
  }

  get(id, token) {
    http.defaults.headers['Authorization'] = `Token ${token}`
    return http.get(`${url + id}/`);
  }

  findByName(name, token) {
    http.defaults.headers['Authorization'] = `Token ${token}`
    return http.get(url, {
          params: {
            gender_type: name
          }
      });
  }


}

export default new GenderDataService();
