import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// Data services
import JobClassificationDataService from "../services/jobClassification.service";

const initialState = {
    data: [],
    status: 'idle',
    error: null
}

/**
   * Get all job classification/skills from job classification data service
   */
export const fetchAllJobClassifications = createAsyncThunk('jobClassification/fetchAllJobClassifications', async (token) => {
    const response = await JobClassificationDataService.getAll(token)
    return response.data
})




export const jobClassificationSlice = createSlice({
  name: 'jobClassification',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAllJobClassifications.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchAllJobClassifications.fulfilled]: (state, action) => {
      state.data = action.payload.reverse()
      state.status = "success"
    },
    [fetchAllJobClassifications.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    }

  }
});

export const selectAllJobClassifications = (state) => state.jobClassification.data


export default jobClassificationSlice.reducer
