import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import talentReducer from './talentSlice';
import talentJobClassificationReducer from './talentJobClassificationSlice';
import talentLanguagesReducer from './talentLanguagesSlice'
import talentLanguagePairReducer from './talentLanguagePairSlice'
import talentPastExperienceReducer from './talentPastExperienceSlice'
import talentSavedJobsReducer from './talentSavedJobsSlice'

import commonReducer from './commonSlice';
import jobClassificationReducer from './jobClassificationSlice';
import languageReducer from './languageSlice';
import jobPostReducer from './jobPostSlice';
import jobDetailReducer from './jobDetailSlice';
import companyReducer from './companySlice';

export default configureStore({
  reducer: {
    auth: authReducer,
    talent: talentReducer,
    talentJobClassification: talentJobClassificationReducer,
    talentLanguages: talentLanguagesReducer,
    talentLanguagePair: talentLanguagePairReducer,
    talentPastExperience: talentPastExperienceReducer,
    talentSavedJobs: talentSavedJobsReducer,
    
    common: commonReducer,
    jobClassification: jobClassificationReducer,
    language: languageReducer,
    jobPost: jobPostReducer,
    jobDetail: jobDetailReducer,
    company: companyReducer
  },
});
