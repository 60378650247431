import React, { Component } from 'react'
import { Link } from 'react-router-dom'

// UI Components Libary
import { Container } from '@material-ui/core';
import { Skeleton, Tabs, Divider } from 'antd';

import Header from '../Header/Header';


const { TabPane } = Tabs;


export default class Settings extends Component {
    render(){
      
        return(
            <div className="settings-container">
                {/* <div className="bg-illustration">
                    <img src={require("../../../assets/background/bg-light.png")}></img>
                </div> */}
                <Header 
                    title="Account Settings"
                    subtext="Lorem ipsum dolor sit amet"
                />

                
                <Container>

                </Container>
            </div>
        )
    }
}