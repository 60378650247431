import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// Data services
import JobPostService from '../services/jobPost.service';

const initialState = {
    data: {
      all: [],
      published: [],
      drafted: [],
      closed: []
    },
    status: 'idle',
    error: null
}


/**
   * Get all Job posts
   */
export const fetchAllJobPosts = createAsyncThunk('jobPost/fetchAllJobPosts', async (token) => {
    const response = await JobPostService.getAll(token)
    // return response.data

    var data = {
      all: response.data,
      published: [],
      drafted: [],
      closed: []
    }
    response.data.forEach((job) => {
      if(job.status === 1) {
          data.published.push(job)
      } else if(job.status === 2) {
          data.drafted.push(job)
      } else if(job.status === 3) {
          data.closed.push(job)
      }
    })

    return data
})



export const jobPostSlice = createSlice({
  name: 'jobPost',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAllJobPosts.pending]: (state, action) => {
      state.status = 'loading'
    },
   
    [fetchAllJobPosts.fulfilled]: (state, action) => {
      state.data = action.payload
      state.status = 'succeeded'
    },
   
    [fetchAllJobPosts.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    }
  }
});

export const selectAllJobPosts = (state) => state.jobPost.data.all
export const selectPublishedJobPosts = (state) => state.jobPost.data.published
export const selectDraftedJobPosts = (state) => state.jobPost.data.drafted
export const selectClosedJobPosts = (state) => state.jobPost.data.closed




export default jobPostSlice.reducer
