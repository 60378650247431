import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import moment from 'moment';

// reactstrap components
import {
    Row,
    Col,
    Card,
    Container
} from "reactstrap"

import { Divider } from 'antd';

import MusicNoteIcon from '@material-ui/icons/MusicNote';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

import './TalentProfile.css'


// import { Carousel, Radio } from 'antd';

// export default class SampleWorks extends Component {
//   state = {
//     dotPosition: 'left',
//   };

//   handlePositionChange = ({ target: { value: dotPosition } }) => this.setState({ dotPosition });

//   render() {
//     const { dotPosition } = this.state;
//     return (
//       <div className="sample-works-container">
//         <Carousel dotPosition={dotPosition}>
//           {this.props.playlist.map((sample) => {
//               return(<div className='player-wrapper'>
//                 <ReactPlayer
//                 className='react-player'
//                 url={sample.url}
//                 width='100%'
//                 height='100%'
//                 />
//             </div>)
//           })}
//         </Carousel>
//       </div>
//     );
//   }
// }


export default class SampleWorks extends Component {
    state = {
        selectedUrl: this.props.playlist[0].url
    }

    handleClick = (url) => {
        this.setState({
            selectedUrl: url
        })
    }

    render() {
        return(
            <Container>
            <div className="spaced-between sample-works-container">

                <div className='player-wrapper'>
                    <ReactPlayer
                    className='react-player'
                    url={this.state.selectedUrl}
                    width='100%'
                    height='100%'
                    />
                </div>


                <Card className="playlist">
                    {this.props.playlist.map((playlistItem, index) => {
                        var icon = <MusicNoteIcon style={{color: "#4d6fff"}} />;
                        var divider = <Divider />

                        if(index === this.props.playlist.length - 1) {
                            divider = <Divider hidden/>
                        }

                        if(playlistItem.type === "video") {
                            icon = <PlayCircleFilledIcon style={{color: "#ff5554"}} />
                        }
                        return(
                            <div key={index}>
                                <Row className="playlist-item" onClick={() => this.handleClick(playlistItem.url)}>
                                    <Col xs="1" className="d-none d-sm-block"><div style={{marginTop: "2px"}}>{index + 1}</div></Col>
                                    <Col xs="1">{icon}</Col>
                                    <Col xs="6"><div style={{marginTop: "2px"}}><b>{playlistItem.title}</b></div></Col>
                                    <Col xs="4">{playlistItem.date}</Col>
                                </Row>
                                {divider}
                            </div>
                        )
                    })}
                </Card>
            </div>
            
            </Container>
        )
    }
}