import React, { useEffect, useState } from 'react';
import { Link, useHistory, Redirect } from 'react-router-dom'
import propTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { fetchTalent } from '../../../state/talentSlice'
import { fetchToken, selectAuth } from '../../../state/authSlice'

import { Field, Form, Formik, ErrorMessage } from "formik";
import * as Yup from 'yup';
import "yup-phone";

import moment from 'moment';


import './AuthComponents.css'
import { Container } from '@material-ui/core';
import { Divider, Alert } from 'antd';


import TalentDataService from "../../../services/talent.service";
import AuthService from "../../../services/auth.service";


import SignUpStep1 from './SignUpStep1'
import SignUpStep2 from './SignUpStep2'
import SignUpStep3 from './SignUpStep3'


const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email")
    .max(50, 'Too Long!')
    .required('Required'),
  password: Yup.string()
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .required('Required'),
  givenName: Yup.string()
    .max(50, 'Too Long!')
    .required('Required'),
  surname: Yup.string()
    .max(50, 'Too Long!')
    .required('Required'),
  gender: Yup.string()
    .required('Required'),
  country: Yup.string()
    .required('Required'),
  mobile: Yup.string()
    .phone()
    .required('Required'),
  birthdate: Yup.string()
    .required('Required')
});

/**
 * Renders the appropiate button component based on the current step of the form 
 */
const StepButton = props => {
  const { step, handleSignup, handleNext, handleBack, handleRefresh, errors, touched, values } = props;
  const history = useHistory()

  const [err, setErr] = useState(null)

  var errBox = <></>

  // Show error box if there is an error 
  if(err != null) {
    errBox = <Alert
      message="Error"
      description={err}
      type="error"
      showIcon
      className="mt-3"
    />
  }
  
  switch (step) {  
    case 1:
      var disabled = false;

      // Disable button if fields are unfilled and errors persist
      if(errors.email || errors.password === 'Required' || !touched.email) {
        disabled = true;
      }

      return (
        <div className="mt-4">
        {errBox}
        <button
          type="button"
          onClick={() => {
            // Create new account with inputted email and password
            handleSignup(values.email, values.password)
            .then(handleNext)
            .catch(err => {
              let errMsg = ""

              if(err.response != undefined) {
                if(typeof(err.response.data) !== 'string') {
                   Object.entries(err.response.data).forEach((errItem) => {
                      errMsg += errItem[0] + ": " + errItem[1]
                    })
                } else {
                  errMsg = err.message        
                }
                     
              } else {
                  errMsg = err.message
              }



              setErr(errMsg)

              // Prevent to go to the next step of signup
              handleRefresh()
            })
          }}
          onKeyPress={(e) => {
            console.log('keypress')
            if (e.keyCode === 13) {
              console.log('enter')
            }
          }}
          className="btn purple-btn full-width"
          disabled={disabled}
        >
          Sign Up
        </button>
        <Divider/>
        <div className="text-center">
            <span className="text-muted">Already have an account?</span>
            <Link to="/signin">
              <span style={{color:'#00bcdf'}}>    <b>Sign In</b></span>
            </Link>
        </div>
        </div>
    );
    case 2:
      var disabled = false;

      // Disable button if fields are unfilled and errors persist
      if(errors.givenName || errors.surname || !touched.givenName || !touched.surname) {
        disabled = true;
      }

      return (
        <div className="spaced-between" style={{width:"100%"}}>
        
          <button
            type="button"
            onClick={() => handleNext()}
            className="btn purple-btn"
            disabled={disabled}>
            Next
          </button>
        </div>
      );
    case 3:
      var disabled = false;

      // Disable button if fields are unfilled and errors persist
      if(errors.gender || !touched.gender) {
        disabled = true;
      }

      return (
      
        <div className="spaced-between" style={{width:"100%"}}>
          <button
              type="button"
              onClick={() => handleBack()}
              className="btn"
            >
              Back
          </button>
          <button
            type="submit"
            className="btn purple-btn"
            disabled={disabled}
          >
            Submit
          </button>
        </div>
      );
    default:
      return <></>;
  }
}

/**
 * Render the appropiate page of the form based on current step
 * @param {number} step 
 * @param {Object} props 
 */
const renderStep = (step, props) => {
  switch (step) {
    case 1:
      return <SignUpStep1 formikProps={props} />;
    case 2:
      return <SignUpStep2 formikProps={props} />;
    case 3:
      return <SignUpStep3 formikProps={props} />;
    case 4:
      return <Redirect to="/" />;
    default:
      return <SignUpStep1 formikProps={props} />;
  }
};

/**
 * Sign up multi-step form wrapper
 * @author Nadia Mayangputri
 */
const SignUp = (props) => {
  const { submitHandler } = props

  const dispatch = useDispatch()
  const auth = useSelector(selectAuth)

  const [step, setStep] = useState(1);
  const [refresh, setRefresh] = useState(0)

  const [dataSent, setDataSent] = useState(false)
  const [avatarSent, setAvatarSent] = useState(false)

  const [error, setError] = useState(null)

  /**
   * Updates step to go to the next page of the form
   */
  const handleNext = () => {
    setStep(step => step + 1);

  }

   /**
   * Updates step to go to the previous page of the form
   */
  const handleBack = () => {
    setStep(step => step - 1);

  }

  const handleRefresh = () => {
    setRefresh(() => Math.random() + 1)
  }


  return(
      <div className="user-info-form-container">
          <Formik
          enableReinitialize
          initialValues={{ 
              email: "",
              password: "",
              avatar: null,
              givenName: "",
              surname: "",
              nickname: "",
              nicknameAsDisplayName: false,
              headline: "",
              birthdate: "",
              gender: 4,
              country: "",
              state: "",
              city: "", 
              mobile: ""
              
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {

            let userInfo = new FormData()
            userInfo.append('email', values.email)
            userInfo.append('password', values.password)

            // Get token and add user profile data with email and password
            AuthService.signin(userInfo)
              .then((res) => {
                // New form data for talent profile basic data
                let basicInfoData = new FormData();

                let city = values.city
                if(values.city === "" && values.state !== "") {
                  city = values.state
                } else  {
                  city = values.country
                } 
                
                basicInfoData.append('given_name', values.givenName);
                basicInfoData.append('last_name', values.surname);
                basicInfoData.append('stage_name', values.nickname);
                basicInfoData.append('is_stage_name_preferred_name', values.nicknameAsDisplayName);
                basicInfoData.append('headline', values.headline);
                basicInfoData.append('date_of_birth', moment(values.birthdate, 'DD/MM/YYYY').format('YYYY-MM-DD'));
                basicInfoData.append('gender', parseInt(values.gender));
                basicInfoData.append('country', values.country);
                basicInfoData.append('city', city);
                basicInfoData.append('phone_number', values.mobile)
                basicInfoData.append('sample_work_url', "n/a")


                let avatarData = new FormData()

                TalentDataService.edit(basicInfoData, res.data.token, {
                  headers: {
                    // Overwrite Axios's automatically set Content-Type
                    'content-type': 'multipart/form-data'
                  }
                })
                .catch((err) => {
                  setError((prev) => prev + err.message)
                })

                // Change avatar if avatar is uploaded
                if(values.avatar != null) {
                  avatarData.append('avatar', values.avatar, values.avatar.name)
                  TalentDataService.changeAvatar(avatarData, res.data.token, {
                    headers: {
                      // Overwrite Axios's automatically set Content-Type
                      'content-type': 'multipart/form-data'
                    }
                  })
                  .catch((err) => {
                    setError((prev) => prev + err.message)
                  })
                }

                console.log(dataSent)
                console.log(avatarSent)
                                
              })
              .then(() => {
                if(error === null) {
                  dispatch(fetchToken(userInfo))
                  setSubmitting(false)

                }
              })    
              

            

        }}>
          {(props) => (
            <Form>
                {renderStep(step, props)}

                {error != null &&
                  <Alert
                  message="Error"
                  description={error}
                  type="error"
                  showIcon
                  className="mt-3"
                />}

                <StepButton 
                step={step} 
                handleSignup={submitHandler} 
                handleNext={handleNext} 
                handleBack={handleBack} 
                handleRefresh={handleRefresh}
                errors={props.errors} 
                touched={props.touched}
                values={props.values}/>
            </Form>
          )}
          </Formik>
      </div>
  )
}


export default SignUp;

StepButton.propTypes = {
  step: propTypes.number.isRequired,
  handleNext: propTypes.func.isRequired, 
  handleBack: propTypes.func.isRequired,
  errors: propTypes.object, 
  touched: propTypes.object
}