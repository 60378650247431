import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// Data services
import TalentPastExperienceService from "../services/talentPastExperience.service";


const initialState = {
  data: {},
  status: 'idle',
  error: null
}

export const fetchTalentPastExperience = createAsyncThunk('talent/fetchTalentPastExperience', async (token) => {
  const response = await TalentPastExperienceService.getAll(token)
  var highlighted = []
  var all = []

  for(let i = 0; i < response.data.length; i++) {
    let exp = response.data[i]
    // separate career highlights from others 
    all = [...all, exp]

    if(exp.is_career_highlight) {
      highlighted = [...highlighted, exp] 
    } 
  }

  var data = {
    highlighted: highlighted,
    all: all
  };
  
  return data
})


// Talent data slice
export const talentPastExperienceSlice = createSlice({
  name: 'talentPastExperience',
  initialState,
  reducers: {
    resetTalentPastExperience: (state) => {
      state.data = initialState.data
    }
  },
  extraReducers: {
    [fetchTalentPastExperience.pending]: 
      (state, action) => {
      state.status = 'loading'
    },
    [fetchTalentPastExperience.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    },
    [fetchTalentPastExperience.fulfilled]: (state, action) => {
      state.data.pastExperience = action.payload.all;
      state.data.highlightedExperience = action.payload.highlighted
      state.status = 'succeeded'
    }
    
  }
});



export default talentPastExperienceSlice.reducer

export const { resetTalentPastExperience } = talentPastExperienceSlice.actions;

export const selectTalentPastExperience = (state) => state.talentPastExperience.data

  