
import React, { Component } from 'react';
// reactstrap components
import {
    Row,
    Col,
    Card,
    CardBody,
    CardImg,
    CardTitle,
    CardText,
} from "reactstrap"


const StatsCard = (props) => (

    <div className="project-card">
        <Card className="card-stats">
            <CardBody>
            <Row>
                <div className="col">
                <span className="h2 mb-0">{props.title}</span>

                </div>
                <Col className="col-auto">
                    {props.icon}
                </Col>
            </Row>
            <div><span className="h5 text-uppercase text-muted mb-0">{props.detailName}</span></div>
            <Row className="pl-2 mt-2">
                {props.details}
            </Row>

            {props.extra}
            
            </CardBody>
        </Card>
    </div>
                             
)

export default StatsCard;