import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom'
import propTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { fetchTalent, selectTalent } from '../../../state/talentSlice'
import { selectAuth } from '../../../state/authSlice'

import { Field, Form, Formik, ErrorMessage } from "formik";
import * as Yup from 'yup';
import "yup-phone";

import moment from 'moment';


import '../../common/AuthComponents/AuthComponents.css'
import { Container } from '@material-ui/core';
import { Divider, Alert, Breadcrumb, notification } from 'antd';

import { WarningFilled } from "@ant-design/icons"

import TalentDataService from "../../../services/talent.service";
import AuthService from "../../../services/auth.service";

import Header from '../../common/Header/Header'
import SignUpStep2 from '../../common/AuthComponents/SignUpStep2'
import SignUpStep3 from '../../common/AuthComponents/SignUpStep3'
import { Redirect } from 'react-router-dom';


const validationSchema = Yup.object().shape({
  givenName: Yup.string()
    .max(50, 'Too Long!')
    .required('Required'),
  surname: Yup.string()
    .max(50, 'Too Long!')
    .required('Required'),
  gender: Yup.string()
    .required('Required'),
  mobile: Yup.string()
    .phone()
    .required('Required'),
  birthdate: Yup.string()
    .required('Required')
});

/**
 * Sign up multi-step form wrapper
 * @author Nadia Mayangputri
 */
const BasicInfoForm = () => {
  const talent = useSelector(selectTalent)

  const history = useHistory()
  const dispatch = useDispatch()
  const auth = useSelector(selectAuth)

  const [initialValues, setInitialValues] = useState({ 
    email: "",
    password: "",
    avatar: null,
    givenName: "",
    surname: "",
    nickname: "",
    nicknameAsDisplayName: false,
    headline: "",
    birthdate: "",
    gender: 4,
    gender_type: "",
    country: "",
    state: "",
    city: "", 
    mobile: ""
  })

  useEffect(() => {
    if(Object.keys(talent).length > 0) {
      setInitialValues({ 
        avatar: talent.avatar,
        givenName: talent.given_name,
        surname: talent.last_name,
        nickname: talent.stage_name,
        nicknameAsDisplayName: talent.is_stage_name_preferred_name,
        headline: talent.headline,
        birthdate: moment(talent.date_of_birth, 'YYYY-MM-DD').format('DD/MM/YYYY').toString(),
        gender: talent.gender_id,
        gender_type: talent.gender,
        country: talent.country,
        city: talent.city, 
        mobile: talent.phone_number
        
    })
    }
  }, [talent, setInitialValues])


  return(
      <div className="user-info-form-container">
          <div className="bg-illustration">
              <img src={require("../../../assets/background/bg-light.png")}></img>
          </div>        
          <Header 
          title="Edit your data" 
          breadcrumb={
          <Breadcrumb>
              <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
              <Breadcrumb.Item><Link to="/profile">My Profile</Link></Breadcrumb.Item>
              <Breadcrumb.Item>Edit data</Breadcrumb.Item>
          </Breadcrumb> }/>

          <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {

            console.log("submitting...")
           
            // New form data for talent data profile basic data
            let basicInfoData = new FormData();
            
            basicInfoData.append('given_name', values.givenName);
            basicInfoData.append('last_name', values.surname);
            basicInfoData.append('stage_name', values.nickname);
            basicInfoData.append('is_stage_name_preferred_name', values.nicknameAsDisplayName);
            basicInfoData.append('headline', values.headline);
            basicInfoData.append('date_of_birth', moment(values.birthdate, 'DD/MM/YYYY').format('YYYY-MM-DD'));
            basicInfoData.append('gender', values.gender);
            basicInfoData.append('country', values.country);
            basicInfoData.append('city', values.city);
            basicInfoData.append('phone_number', values.mobile)
            basicInfoData.append('sample_work_url', "n/a")

            TalentDataService.edit(basicInfoData, auth.token, {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                'content-type': 'multipart/form-data'
              }
            }).then(() => {
            // Change avatar if avatar is uploaded
            let avatarData = new FormData()
            if(values.avatar != null && typeof(values.avatar) !== 'string') {
              console.log('avatar changed')
              avatarData.append('avatar', values.avatar, values.avatar.name)
              TalentDataService.changeAvatar(avatarData, auth.token, {
                headers: {
                  // Overwrite Axios's automatically set Content-Type
                  'content-type': 'multipart/form-data'
                }
              })
              .then(() => {
                // Once avatar is updated re-fetch talent data, then redirect to profile
                dispatch(fetchTalent(auth.token))
                window.location.href = '/profile'
              })
            } else {
              // If there's no avatar change redirect to profile
              window.location.href = '/profile'
            }
            })
            .catch((err) => {
              notification.open({
                message: 'Error',
                description: err.message,
                icon: <WarningFilled style={{ color: '#ed5e94' }} />,
              })
            })
            .then()
            console.log("Submitted!")

          
        }}>
            { (props) => (
            <Form>
              <Container className="content-container">
                <SignUpStep2 formikProps={props}/>
                <SignUpStep3 formikProps={props}/>
                <div className="flex-centered w-100">
                    <div>
                      <Link to="/profile">
                          <button
                              type="button"
                              className="btn px-4"
                              >
                              Cancel
                          </button>
                      </Link>
                    </div>
                    <button
                        type="submit"
                        className="btn mx-0 px-4 blue-btn">
                        Submit
                    </button>
                </div>
              </Container>
              {/* <pre>{JSON.stringify(props.values, null, 2)}</pre> */}
            </Form>
          )}
          </Formik>
      </div>
  )
}


export default BasicInfoForm;