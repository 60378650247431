import React, { useState, useEffect } from 'react';
import { Field, Form, Formik, ErrorMessage } from "formik";
import * as Yup from 'yup';
import moment from 'moment';
import { Redirect, Link } from 'react-router-dom'

import { useSelector } from 'react-redux'
import { selectAuth } from '../../../state/authSlice'

import './Form.css'

import {
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col
  } from "reactstrap";

import { Container } from '@material-ui/core';

  
import { Breadcrumb, notification } from 'antd'
import { WarningFilled } from '@ant-design/icons'


import FormikDateTime from '../../common/FormikDateTime'

import Header from "../../common/Header/Header"

import TalentPastExperienceService from "../../../services/talentPastExperience.service";



const DATE_FORMAT = 'YYYY-MM-DD'

const talentFormSchema = Yup.object().shape({
  name: Yup.string()
    .max(50, 'Too Long!')
    .required('Required'),
  role: Yup.string()
    .max(50, 'Too Long!')
    .required('Required'),
  natureOfProject: Yup.string()
    .required('Required'),
  company: Yup.string()
    .required('Required'),

});


const ProjectForm = (props) => {
    const { handleClose, data } = props;
    const auth = useSelector(selectAuth)

    const [initialValues, setInitialValues] = useState({ 
      name:"",
      role:"",
      company:"",
      natureOfProject:"",
      startDate:"",
      endDate:"",
      isOngoing: false,
      isCareerHighlight: false
   })

   useEffect(() => {
     if(data != null || data != undefined) {
        var endDate = moment(data.end_date, 'YYYY-MM-DD').format('DD/MM/YYYY').toString()
        if(data.is_ongoing) {
          endDate = ""
        }
        setInitialValues({ 
          name: data.project_title,
          role: data.role,
          company: data.company_name,
          natureOfProject: data.nature_of_project,
          startDate: moment(data.start_date, 'YYYY-MM-DD').format('DD/MM/YYYY').toString(),
          endDate: endDate,
          isOngoing: data.is_ongoing,
          isCareerHighlight: data.is_career_highlight
    
        })
     } else {
        setInitialValues({ 
          name:"",
          role:"",
          company:"",
          natureOfProject:"",
          startDate:"",
          endDate:"",
          isOngoing: false,
          isCareerHighlight: false
    
        })
     }
   }, [data, setInitialValues])

    return(
      <div className="new-project-container">
        {/* <div className="d-md-none">
          <div className="bg-illustration">
              <img src={require("../../../assets/background/bg-light.png")}></img>
          </div>
        
          
          <Header 
          title="Experience" 
          // subtext="Please add your past and/or current projects/experience that you'd like to include in your profile."
          breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
            <Breadcrumb.Item><Link to="/profile">My Profile</Link></Breadcrumb.Item>
            <Breadcrumb.Item>Add Project</Breadcrumb.Item>
          </Breadcrumb> }/>
        </div> */}

      <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={talentFormSchema}
      onSubmit={(values, { setSubmitting }) => {
          let projectsData = new FormData();
          projectsData.append('project_title', values.name);
          projectsData.append('role', values.role);
          projectsData.append('company_name', values.company);
          projectsData.append('nature_of_project', values.natureOfProject);
          projectsData.append('start_date', moment(values.startDate,'DD/MM/YYYY').format(DATE_FORMAT));

          if(!values.isOngoing) {
            projectsData.append('end_date', moment(values.endDate,'DD/MM/YYYY').format(DATE_FORMAT));
          } else {
            // Temporary: make end date 01/01/2999 date if it's ongoing - should disabled end date as a required field from the backend
            projectsData.append('end_date', moment('01/01/2999','DD/MM/YYYY').format(DATE_FORMAT));
          }
  
          projectsData.append('is_ongoing', values.isOngoing);
          projectsData.append('is_career_highlight', values.isCareerHighlight);

          // If data is not null, this form is used for edit instead
          if(data != null) {
            TalentPastExperienceService.update(data.id, projectsData, auth.token, {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                'content-type': 'multipart/form-data'
              }
            })
            .then(() => {
              setSubmitting(false);
              handleClose()
              window.location.href = '/profile'
            }) 
            .catch((err) => {
              notification.open({
                message: 'Error',
                description: err.message,
                icon: <WarningFilled style={{ color: '#ed5e94' }} />,
              })
            })  
            
          } else {
            // Data is null
            // Create post request to add experience to database
            TalentPastExperienceService.create(projectsData, auth.token, {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                'content-type': 'multipart/form-data'
              }
            })
            .then(() => {
              setSubmitting(false);
              handleClose()
              window.location.href = '/profile'
            }) 
            .catch((err) => {
              notification.open({
                message: 'Error',
                description: err.message,
                icon: <WarningFilled style={{ color: '#ed5e94' }} />,
              })
            })  
          }
          
        }}>
        { (props, isSubmitting) => (
        <Form>
        
            <Container>
          

            {/* <Container className="content-container"> */}
                
            
                <FormGroup>
                    <div style={{marginTop: "10px"}}>
                        <Row>   
                            <Col xs="12" md="6">
                                <FormGroup>
                                    <label>Project Name / Alias</label><br></br>
                                    <Field
                                    type="text"
                                    label="Project Name"
                                    name="name">
                                    {({ field, form: { isSubmitting } }) => (<Input {...field} disabled={isSubmitting} type="text" placeholder="Project Name" />
                                    )}
                                    </Field>
                                    <ErrorMessage name="name" component="span"/>
                                </FormGroup>
                            </Col>
                            <Col xs="12" md="6">
                              <FormGroup>
                                <label>Company</label>
                                <Field
                                  type="text"
                                  label="Company"
                                  name="company">
                                  {({ field, form: { isSubmitting } }) => (
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-building" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input {...field} disabled={isSubmitting} type="text" placeholder="Company" />
                                    </InputGroup>
                                    )}
                                </Field>
                                <ErrorMessage name="company" component="span"/>
                              </FormGroup>
                            </Col>
                          
                        </Row>

                        <Row>
                          <Col xs="12">
                            
                            <FormGroup>
                              <label>Nature of Project</label><br></br>
                              <Field
                                type="text"
                                label="Position / Role"
                                name="natureOfProject">
                                {({ field, form: { isSubmitting } }) => (<Input {...field} disabled={isSubmitting} type="text" placeholder="Nature of Project" />
                                )}
                              </Field>
                              <span className="help-text">Example: Tagalog dubbing, English subtitling, German voice acting, etc.</span>
                            </FormGroup>
                            <ErrorMessage name="natureOfProject" component="span"/>
                          </Col>
                          <Col xs="12">
                            <FormGroup>
                              <label>Position / Role</label><br></br>
                              <Field
                                type="text"
                                label="Position / Role"
                                name="role">
                                {({ field, form: { isSubmitting } }) => (<Input {...field} disabled={isSubmitting} type="text" placeholder="Position / Role" />
                                )}
                              </Field>
                              <ErrorMessage name="role" component="span"/>
                            </FormGroup>
                          </Col>
                          
                          </Row>
                          
                          <Row>
                            <Col xs="12" md="6">
                              <FormGroup>
                                <Field
                                  name="isOngoing"
                                  type="checkbox">
                                    {({ field, form: { isSubmitting } }) => (
                                    <div className="custom-control custom-checkbox mb-3">
                                      <input
                                        {...field}
                                        disabled={isSubmitting}
                                        className="custom-control-input"
                                        name="isOngoing"
                                        type="checkbox"
                                        id="isOngoing" />

                                      <label className="custom-control-label checkbox-label font-weight-normal" htmlFor="isOngoing">
                                        I am currently working on this project
                                      </label>
                                    </div>
                                    )}
                                </Field>
                              </FormGroup>
                            </Col>
                          </Row>
                            
                          <Row>
                            <Col xs="12" md="6">
                              <FormGroup>
                                <label>Start Date</label>

                                <Field
                                  name="startDate"
                                  type="date"
                                  label="Start date"
                                  component={FormikDateTime}
                                  />
                              </FormGroup>
                            </Col>
                            <Col xs="12" md="6">
                              <FormGroup>
                                <label>End Date</label>

                                <Field
                                  name="endDate"
                                  type="date"
                                  label="End date"
                                  InputLabelProps={{disabled: props.values.isOngoing}}
                                  component={FormikDateTime}
                                  />
                              </FormGroup>
                            </Col>
                          </Row>
                          
                          <Row>
                            <Col xs="12" md="6">
                              <FormGroup style={{marginTop:"20px"}}>
                                <Field
                                  name="isCareerHighlight"
                                  type="checkbox">
                                    {({ field, form: { isSubmitting } }) => (
                                    <div className="custom-control custom-checkbox mb-3">
                                      <input
                                        {...field}
                                        disabled={isSubmitting}
                                        className="custom-control-input"
                                        name="isCareerHighlight"
                                        type="checkbox"
                                        id="isCareerHighlight" />

                                      <label className="custom-control-label checkbox-label font-weight-normal" htmlFor="isCareerHighlight">
                                        Add as a <b>Career Highlight</b>
                                      </label>
                                    </div>
                                    )}
                                </Field>
                              </FormGroup>
                            </Col>
                          </Row>
                            
                          </div>
                
                        
                </FormGroup>
                <div className="spaced-between" style={{width:"100%"}}>
                    <button
                        type="button"
                        onClick={handleClose}
                        className="btn">
                        Cancel
                    </button>
                    {data != null ? (
                    <button
                        type="submit"
                        className="btn purple-btn">
                        Save Changes
                    </button>) : (
                    <button
                        type="submit"
                        className="btn purple-btn">
                        Add Experience
                    </button>)}
                </div>
            {/* </Container> */}
            </Container>
            
          
        </Form>
      )}
      
      </Formik>
      
      </div>
  )
}


export default ProjectForm;