import React from 'react'
import { Link } from 'react-router-dom'

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardImg,
    CardTitle,
    CardText,
    Container,
    Badge
  } from "reactstrap"

import { Skeleton } from 'antd';


import AddIcon from '@material-ui/icons/Add';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';


const EmptyCard = () => {

    return(
    <Card>
        <CardBody>
            <Skeleton active/>
        </CardBody>
    </Card>
        
    )
}

export default EmptyCard