import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// Data services
import EmpTypeDataService from '../services/empType.service'
import ProjectTypeDataService from '../services/projectType.service'
import ProjectSubtypeDataService from '../services/projectSubtype.service'

const initialState = {
    projectTypes: [],
    projectSubtypes: [],
    empTypes: [],
    status: 'idle',
    error: null
}

/**
   * Get all Project types
   */
  export const fetchAllProjectTypes = createAsyncThunk('jobDetail/fetchAllProjectTypes', async (token) => {
    const response = await ProjectTypeDataService.getAll(token)
    return response.data
})

/**
   * Get all Project Subtypes
   */
  export const fetchAllProjectSubtypes = createAsyncThunk('jobDetail/fetchAllProjectSubtypes', async (token) => {
    const response = await ProjectSubtypeDataService.getAll(token)
    return response.data
})

/**
   * Get all Employment types
   */
  export const fetchAllEmpTypes = createAsyncThunk('jobDetail/fetchAllEmpTypes', async (token) => {
    const response = await EmpTypeDataService.getAll(token)
    return response.data
})


export const jobDetailSlice = createSlice({
  name: 'jobDetail',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAllProjectTypes.pending]: (state, action) => {
      state.status = 'project types loading'
      state.error = action.payload
    },
    [fetchAllProjectSubtypes.pending]: (state, action) => {
      state.status = 'project subtypes loading'
      state.error = action.payload
    },
    [fetchAllEmpTypes.pending]: (state, action) => {
    state.status = 'emp types loading'
    state.error = action.payload
    },

    [fetchAllProjectTypes.fulfilled]: (state, action) => {
      state.projectTypes = action.payload.reverse()
      state.status = 'project types succeeded'
    },
    [fetchAllProjectSubtypes.fulfilled]: (state, action) => {
      state.projectSubtypes = action.payload.reverse()
      state.status = 'project subtypes succeeded'
    },
    [fetchAllEmpTypes.fulfilled]: (state, action) => {
      state.empTypes = action.payload.reverse()
      state.status = 'emp types succeeded'
    },
    [fetchAllProjectTypes.rejected]: (state, action) => {
      state.status = 'project types failed'
      state.error = action.payload
    },
    [fetchAllProjectTypes.rejected]: (state, action) => {
      state.status = 'project subtype failed'
      state.error = action.payload
    },
    [fetchAllProjectTypes.rejected]: (state, action) => {
    state.status = 'emp types failed'
    state.error = action.payload
    },


  }
});

export const selectAllProjectTypes = (state) => state.jobDetail.projectTypes
export const selectAllProjectSubtypes = (state) => state.jobDetail.projectSubtypes
export const selectAllEmpTypes = (state) => state.jobDetail.empTypes


export default jobDetailSlice.reducer
