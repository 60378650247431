import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {capitalizeFirstLetter} from '../constants'

// Data services
import TalentLanguageService from "../services/talentLanguage.service";


const initialState = {
  data: [],
  status: 'idle',
  error: null
}

export const fetchTalentLanguage = createAsyncThunk('talent/fetchTalentLanguage', async (token) => {
  const response = await TalentLanguageService.getAll(token)
  var languages = []

  for(let i = 0; i < response.data.length; i++) {
    var currData = response.data[i];
    var language = currData.language;
    var proficiency = capitalizeFirstLetter(currData.proficiency.toLowerCase());

    languages = [...languages, {'language': language, 'proficiency': proficiency}]
  }

  return languages;
})



// Talent Language slice
export const talentLanguagesSlice = createSlice({
  name: 'talentLanguages',
  initialState,
  reducers: {
    resetTalentLanguages: (state) => {
      state.data = initialState.data
    }
  },
  extraReducers: {
    [fetchTalentLanguage.pending]: 
      (state, action) => {
      state.status = 'loading'
    },
    [fetchTalentLanguage.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    },
    [fetchTalentLanguage.fulfilled]: (state, action) => {
      state.data = action.payload
      state.status = 'succeeded'
    }
    
  }
});

export default talentLanguagesSlice.reducer

export const { resetTalentLanguages } = talentLanguagesSlice.actions;

export const selectTalentLanguages = (state) => state.talentLanguages.data

  