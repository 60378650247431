import React from 'react'

const JobClassificationList = (props) => (
    
    <div className="job-label-container">
        {props.data.map((jobClassification, index) => {
            let jobClassificationText = jobClassification.replaceAll("_", " ")

            return(
                    <span key={index} className="job-label" style={{backgroundColor: props.bgColor, color: props.color}}>
                        {jobClassificationText}
                    </span>
                )
        })}
    </div>

)

export default JobClassificationList;