import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// Data services
import TalentDataService from "../services/talent.service";
import GenderDataService from "../services/gender.service"


const initialState = {
  data: {},
  status: 'idle',
  error: null
}
/**
 * Fetch talent profile data
 */
export const fetchTalent = createAsyncThunk('talent/fetchTalent', async (token, dispatch) => {
    // Get talent profile data

    // const response = await TalentDataService.get(token)
    // return response.data

    var data;

    // Get talent profile data
    const talent = await TalentDataService.get(token)

    // Get most recent avatar of talent
    const talentAvatar = await TalentDataService.getAvatar(token)

    // Get gender id
    const gender = await GenderDataService.findByName(talent.data.gender, token)
    data = talent.data
    data = {...data, avatar: talentAvatar.data[0].avatar, gender_id: gender.data[0].id}

    return data

})

// Talent data slice
export const talentSlice = createSlice({
  name: 'talent',
  initialState: {
    data: {},
    status: 'idle',
    error: null
  },
  reducers: {
    resetTalent: (state) => {
      state.data = initialState.data
    }
  },
  extraReducers: {
    [fetchTalent.pending]: 
      (state, action) => {
      state.status = 'loading'
    },
    [fetchTalent.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    },
    [fetchTalent.fulfilled]: (state, action) => {
      state.data = action.payload
      state.status = 'succeeded'
    }
    
  }
});


export default talentSlice.reducer

export const { resetTalentData } = talentSlice.actions;

export const selectTalent = (state) => state.talent.data

  