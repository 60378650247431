import http from "../http-common";

const url = "api/core/specialization/"

class SpecialisationDataService {
  getAll(token) {
    http.defaults.headers['Authorization'] = `Token ${token}`
    return http.get(url);
  }

  get(id, token) {
    http.defaults.headers['Authorization'] = `Token ${token}`
    return http.get(`${url + id}/`);
  }

  findByJobClassification(jobClassificationID, token) {
    http.defaults.headers['Authorization'] = `Token ${token}`
    return http.get(url, {
          params: {
            skill_category_id: jobClassificationID
          }
      });
  }
}

export default new SpecialisationDataService();
