import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { Field, Form, Formik, ErrorMessage } from "formik";
import csc from 'country-state-city';

//Redux store
import { useSelector, useDispatch } from 'react-redux'
import { selectAuth } from '../../../state/authSlice'
import { selectTalent } from '../../../state/talentSlice'
import { selectTalentSavedJobs } from '../../../state/talentSavedJobsSlice'
import { selectAllJobPosts, selectPublishedJobPosts } from '../../../state/jobPostSlice'
import { selectAllJobClassifications } from '../../../state/jobClassificationSlice'
import { selectAllEmpTypes } from '../../../state/jobDetailSlice'

// Data services
import JobPostService from '../../../services/jobPost.service'

// UI Components Libary
import { Skeleton, Tabs, Divider, Empty, Card, Select, notification } from 'antd';
import {
    FormGroup,
    Container,
    Row,
    Col
  } from "reactstrap";

// Custom Components
import JobPostCard from "../../common/JobPost/JobPostCard"
import Header from '../../common/Header/Header';
import EmptyCard from '../../common/Card/EmptyCard'

// Icons
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';

import { DropboxCircleFilled, WarningFilled } from "@ant-design/icons"

import './TalentJobPostList.css'
import { capitalizeFirstLetter } from '../../../constants';

const { TabPane } = Tabs;
const { Option } = Select;


const TalentJobPostList = (props) => {
    
    var appliedJobs = [];

    // const jobPosts = useSelector(selectPublishedJobPosts)

    const [jobPosts, setJobPosts] = useState([])
    const [filter, setFilter] = useState({status: 1})
    const [isJobPostsLoading, setIsJobPostsLoading] = useState(false)

    const [savedJobs, setSavedJobs] = useState([])
    const [isSavedJobsLoading, setIsSavedJobsLoading] = useState(true)
    const [isAppliedJobsLoading, setIsAppliedJobsLoading] = useState(true)

    const auth = useSelector(selectAuth)
    const talentSavedJobs = useSelector(selectTalentSavedJobs)
    const jobClassifications = useSelector(selectAllJobClassifications)
    const empTypes = useSelector(selectAllEmpTypes)

    const jobPostStatus = useSelector((state) => state.jobPost.status)

    useEffect(() => {
        // Fetch saved jobs data
        if(talentSavedJobs.length > 0) {
            // Reset save jobs list
            setSavedJobs([])

            // Get saved jobs data
            talentSavedJobs.forEach((id) => {
                JobPostService.get(id, auth.token)
                .then(res => {
                    setIsSavedJobsLoading(false)
                    setSavedJobs(prev => [...prev, res.data])
                })
                .catch((err) => {
                    notification.open({
                      message: 'Error',
                      description: err.message,
                      icon: <WarningFilled style={{ color: '#ed5e94' }} />,
                    })
                  })
            })
        } else {
            setIsSavedJobsLoading(false)
        }

        getJobPosts()

    }, [talentSavedJobs, filter, setSavedJobs])

    /**
     * 
     * @param {object} filter
     */
    const getJobPosts = () => {
        setIsJobPostsLoading(true)
        JobPostService.getWithFilter(filter, auth.token)
        .then(res => {
            setIsJobPostsLoading(false)
            setJobPosts(res.data)
        })
        .catch((err) => {

            notification.open({
              message: 'Error',
              description: err.message,
              icon: <WarningFilled style={{ color: '#ed5e94' }} />,
            })
          })
    }


    return(
        <div className="talent-opportunities-container">
            {/* <div className="bg-illustration">
                <img src={require("../../../assets/background/bg-light.png")}></img>
            </div> */}
            <Header 
                title="Opportunities"
                subtext={<p>Lorem ipsum dolor sit amet</p>}
            />

            <div className="tab-container">
                <Tabs defaultActiveKey="1" className="tab-content" centered tabBarGutter={70}>
                    <TabPane tab="ALL OPPORTUNITIES" key="1">
                        {/* Job Filters */}
                        <Container>
                            <Formik
                            enableReinitialize
                            initialValues={{country: null, job_classification_id: null, emp_type_id: null}}
                            onSubmit={(values, { setSubmitting }) => {

                                // Set filter paramenter, if null or Nan replace with empty string
                                var params = {
                                    country: values.country || "", 
                                    job_classification_id: parseInt(values.job_classification_id) || "", 
                                    emp_type_id: parseInt(values.emp_type_id) || ""
                                }
                                setFilter({...filter, ...params})
                                setSubmitting(false)
                            }}>
                                {(props, isSubmitting) => (
                                    <Form>
                                        <Row>
                                            <Col xs='12' md="3">
                                                <FormGroup>
                                                    <Field
                                                        type="text"
                                                        label="Country"
                                                        name="country"
                                                        id="country">
                                                        {({ field, form: { isSubmitting } }) => (
                                                        <Select
                                                        {...field} disabled={isSubmitting}
                                                        showSearch
                                                        placeholder="Select country"
                                                        style={{ width: '100%' }}
                                                        onChange={(e) => {
                                                            let country = csc.getCountryById(e)
                                                            props.setFieldValue('country', country.name)
                                                        }}
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().startsWith(input.toLowerCase())
                                                        }
                                                        >
                                                        {csc.getAllCountries().map(country => (
                                                            <Option key={country.id}>{country.name}</Option>
                                                        ))}
                                                        </Select>
                                                        )}

                                                    </Field>
                                                </FormGroup>
                                            </Col>
                                            <Col xs='12' md="3">
                                                <FormGroup>
                                                    <Field
                                                        type="text"
                                                        label="Job Classification"
                                                        name="job_classification_id"
                                                        id="job_classification_id">
                                                        {({ field, form: { isSubmitting } }) => (
                                                        <Select
                                                        {...field} disabled={isSubmitting}
                                                        showSearch
                                                        placeholder="Select skill"
                                                        style={{ width: '100%' }}
                                                        onChange={(e) => {
                                                            props.setFieldValue('job_classification_id', e)
                                                        }}
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().startsWith(input.toLowerCase())
                                                        }
                                                        >
                                                        {jobClassifications.map(jobClass => (
                                                            <Option key={jobClass.id}>{
                                                                capitalizeFirstLetter(jobClass.name.replaceAll("_", " ").toLowerCase())
                                                            }</Option>
                                                        ))}
                                                        </Select>
                                                        )}

                                                    </Field>
                                                </FormGroup>
                                            </Col>
                                            <Col xs='12' md="3">
                                                <FormGroup>
                                                    <Field
                                                        type="text"
                                                        label="Employment Type"
                                                        name="emp_type_id"
                                                        id="emp_type_id">
                                                        {({ field, form: { isSubmitting } }) => (
                                                        <Select
                                                        {...field} disabled={isSubmitting}
                                                        showSearch
                                                        placeholder="Select employment type"
                                                        style={{ width: '100%' }}
                                                        onChange={(e) => {
                                                            props.setFieldValue('emp_type_id', e)
                                                        }}
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().startsWith(input.toLowerCase())
                                                        }
                                                        >
                                                        {empTypes.map(empType => (
                                                            <Option key={empType.id}>{
                                                                capitalizeFirstLetter(empType.name.replaceAll("_", " ").toLowerCase())
                                                            }</Option>
                                                        ))}
                                                        </Select>
                                                        )}

                                                    </Field>
                                                </FormGroup>
                                            </Col>
                                            <Col xs="12" md="1">
                                                <button
                                                type="button"
                                                className="py-1 px-3 m-0 btn rounded"
                                                onClick={() => {
                                                    props.resetForm()
                                                    props.submitForm()
                                                }}>
                                                    Reset
                                                </button>
                                            </Col>
                                            <Col xs="12" md="2">
                                                <button
                                                type="submit"
                                                className="px-5 py-1 purple-btn rounded">
                                                    Apply
                                                </button>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                            
                        </Container>

                        {/* Job posts */}
                        <Container className="grid-container mt-4">
                            {jobPosts.length > 0 ? (jobPosts.map((job) => {
                                var bgColor = "#fff", color = "#35424a", iconColor = "#35424a";
                                
                                return(
                                        <div className="mb-3">
                                        <JobPostCard 
                                            jobId={job.id}
                                            title={<Link className="link" to={`/opportunities/${job.id}`}>{job.job_title}</Link>}
                                            showCompany={job.show_company}
                                            company={job.company_id}
                                            empType={job.emp_type_id}
                                            location={job.city + ", "+ job.country}
                                            bgColor={bgColor}
                                            textColor={color}
                                            iconColor={iconColor}                                         
                                            width="100%"
                                            extra={
                                                <>
                                                    <div className="text-right w-100 text-muted text-xs">{moment(job.dt_published, "YYYY-MM-DD").fromNow()}</div>
                                                </>
                                            }
                                        />
                                        </div>
                                    
                                )
                            })) : (
                                 // If it's loading, show loading card component, if it's not it means there is no data to load
                                 jobPostStatus === "loading" || isJobPostsLoading ? (  
                                    <>
                                        {Array(6).map(() => (
                                            <EmptyCard />
                                        ))}
                                     </>
                                 ) : (
                                    <>
                                        <div></div>
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Opportunities to show"/>
                                        <div></div>
                                    </>
                                 )
                            )}
                        </Container>
                    </TabPane>
                    <TabPane tab="SAVED JOBS" key="2">
                        <Container className="grid-container">
                            {savedJobs.length > 0 ? (savedJobs.map((job) => {
                                var bgColor = "#fff", color = "#35424a", iconColor = "#35424a";
                                
                                return(
                                        <div className="mb-3">
                                        <JobPostCard 
                                            jobId={job.id}
                                            title={<Link className="link" to={`/opportunities/${job.id}`}>{job.job_title}</Link>}
                                            company={job.company_id}
                                            showCompany={job.show_company}
                                            empType={job.emp_type_id}
                                            location={job.city + ", "+ job.country}
                                            bgColor={bgColor}
                                            textColor={color}
                                            iconColor={iconColor}                                         
                                            width="100%"
                                            extra={
                                                <>
                                                    <div className="text-right w-100 text-muted text-xs">{moment(job.dt_published, "YYYY-MM-DD").fromNow()}</div>
                                                </>
                                            }
                                        />
                                        </div>
                                    
                                )
                            })) : (
                                // If it's loading, show loading card component, if it's not it means there is no data to load
                                isSavedJobsLoading ? (  
                                    <>
                                        <EmptyCard />
                                        <EmptyCard />
                                        <EmptyCard />
                                    </>
                                 ) : (
                                    <>
                                        <div></div>
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No saved opportunities"/>
                                        <div></div>
                                    </>
                                 )
                            )}
                            </Container>
                    </TabPane>
                    <TabPane tab="APPLICATIONS" key="3">
                        <Container className="grid-container">
                            {appliedJobs.length > 0 ? (appliedJobs.map((job) => {
                                var bgColor = "#fff", color = "#35424a";

                                if(job.status === "In Review") {
                                    // bgColor = "#ed5e94"
                                    bgColor="linear-gradient(40deg, #108ee9, #70cde7)"
                                    color = "rgba(255, 255, 255, 0.9)"
                                } 

                                return(
                                    <div className="mb-3">
                                        <JobPostCard 
                                            jobId={job.id}
                                            title={job.jobTitle}
                                            label={job.status}
                                            company={job.company}
                                            showCompany={job.show_company}
                                            location={job.location}
                                            bgColor={bgColor}
                                            textColor={color}
                                            width="100%"
                                        />
                                    </div>
                                )
                            })) : (
                                // If it's loading, show loading card component, if it's not it means there is no data to load
                                isAppliedJobsLoading ? (  
                                    <>
                                        <EmptyCard />
                                        <EmptyCard />
                                        <EmptyCard />
                                    </>
                                 ) : (
                                    <>
                                        <div></div>
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="You have no job applications"/>
                                        <div></div>
                                    </>
                                 )
                            )}      
                        </Container>                           
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
    
}

export default TalentJobPostList;