import http from "../http-common";

const url = "api/core/language/"

class LanguageDataService {
  getAll(token) {
    http.defaults.headers['Authorization'] = `Token ${token}`
    return http.get(url);
  }

  get(id, token) {
    http.defaults.headers['Authorization'] = `Token ${token}`
    return http.get(`${url + id}/`);
  }

}

export default new LanguageDataService();
