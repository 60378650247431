import React from 'react';
import { Route, Redirect } from 'react-router-dom'

import { useSelector } from 'react-redux'
import { selectAuth } from '../../state/authSlice'


/**
 * 
 * Component used to determine if a user is authenticated and
 * if they are allowed to visit the page they navigated to.
 * 
 */
const PrivateRoute = ({component: Component, exact, ...rest}) => {
  const auth = useSelector(selectAuth)
  return (
    <Route
      {...rest}
      render={(props) => auth.isAuthenticated
        ? (<Component exact={exact} {...props} {...rest}/>)
        : (<Redirect to="/signin" />)
    }
    />
  )
}

export default PrivateRoute;